import React from 'react';
import '../App.css';
import Footer from '../footer/Footer';
import { HeroAbout } from '../hero/hero-about';
import { InfoAbout } from '../info/infoabout';

const About = () => {
  return (
      <>
          <HeroAbout />
          <InfoAbout />
          <Footer />
      </>
  )
}

export default About;