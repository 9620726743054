import { React } from 'react';
import $ from 'jquery';
import { fabric } from 'fabric';
import { Tabs, Tab } from "react-bootstrap";
import 'spectrum-colorpicker';
import 'materialize-css';
import './quantispacer.css';
import './canvasPage.css';
import { FaMousePointer, FaPalette, FaPencilAlt, FaSearchPlus } from 'react-icons/fa';


export default function QuantiSpacer() {

  var min = 99, max = 999999, calibrated = 1, temp = 0;
  var pointArray = [], lineArray = [];
  var activeLine = "", activeShape = false, polygonMode = true;
  var costs = [], costs1 = [], costs2 = [], costs3 = [], costs4 = [];
  var arcosts = [], arcosts1 = [], arcosts2 = [], arcosts3 = [], arcosts4 = [];
  var countcosts = [], countcosts1 = [], countcosts2 = [], countcosts3 = [], countcosts4 = [];
  var startx = [], endx = [], starty = [], endy = [];
  var count = [], count1 = [], count2 = [], count3 = [], count4 = [];
  var text, canvas, line, isDown, mode, count_id, length_id, area_id;
  var trigger = "1"; var zoomMode = false;
  var lenIds = [], lenIds1 = [], lenIds2 = [], lenIds3 = [], lenIds4 = [];
  var areaIds = [], areaIds1 = [], areaIds2 = [], areaIds3 = [], areaIds4 = [];
  var countIds = [], countIds1 = [], countIds2 = [], countIds3 = [], countIds4 = [];
  var countAction = 0, lengthAction = 0, areaAction = 0;
  var countId = 1, lengthId = 1, areaId = 1;
  var tabMode = "calibrate";
  var bgDraw = "#142443", bgSelect = "#142443", bgZoom = "#142443";
  var custom_color = "rgb(255, 0, 0)";
  
  const handleSelect = (activeKey) => {
    if (activeKey === "calibrate"){
      tabMode = "calibrate";
    } else if (activeKey == "length"){
      tabMode = "length";
    } else if (activeKey == "area"){
      tabMode = "area";
    } else if (activeKey == "count"){
      tabMode = "count";
    }
  }

  const hideDiv = {
    display: "none"
  }

  const rowStyle = {
    height: "100%",
    width: "100%",
    padding: "0"
  };

  const paddingStyle = {
    padding: "0px"
  };

  $(window).on('load', function(){
    document.getElementById('modal-btn').onclick = function () {
      document.getElementById('modal').style.display = "none";
    };
    document.getElementById("colorPalette").style.color = "rgb(255, 0, 0)";
    prototypefabric.initCanvas();
    $('#create-polygon').click(function() {
      prototypefabric.polygon.drawPolygon(); 
    });
  });

  var prototypefabric = new function () {
        this.initCanvas = function () {
            canvas = new fabric.Canvas('c', { selection: false });
            canvas.perPixelTargetFind = true;
            canvas.targetFindTolerance = 4;
            fabric.Object.prototype.transparentCorners = false; 
            canvas.setWidth(1050);
            canvas.setHeight(700);
            document.getElementById("costrate").defaultValue = 0;
            document.getElementById("costarea").defaultValue = 0;
            document.getElementById("costcount").defaultValue = 0;
            $("#select").click(function(e){
              e.stopImmediatePropagation();  
              canvas.selection = false;
              canvas.isDrawingMode = false;
              if (bgSelect == "#142443") {
                document.getElementById("select").style.background = "#111";
                bgSelect = "#111";
                bgDraw = "#142443";
                document.getElementById("draw").style.background = "#142443";
                mode="select"; 
              } else {
                document.getElementById("select").style.background = "#142443";
                bgSelect = "#142443";
                document.getElementById("draw").style.background = "#111";
                mode="draw"; 
              }
            });
            $("#draw").click(function(e){
              e.stopImmediatePropagation();
              if (bgDraw == "#142443") {
                document.getElementById("draw").style.background = "#111";
                bgDraw = "#111";
                bgSelect = "#142443";
                document.getElementById("select").style.background = "#142443";
                mode="draw"; 
              } else {
                document.getElementById("draw").style.background = "#142443";
                bgDraw = "#142443";
                document.getElementById("select").style.background = "#111";
                mode="select"; 
              }
            });
            $("#custom-color").spectrum({
              showPalette: true,
              showSelectionPalette: true,
              color: 'red',
              hideAfterPaletteSelect: true,
              showInitial: true,
              palette: [
                ['red', 'blue', 'green'], 
                ['yellow', 'orange', 'pink'], 
                ['purple', 'black', 'white']
              ],
              change: function(selectedColor) {
                $("#colorPalette").css("color", selectedColor.toHexString());
              }
            });
            $("#btn-toggle").click(function(e) {
              e.stopImmediatePropagation();
              $("#custom-color").spectrum("toggle");
            });
            $("#zoom-btn").click(function(e){
              e.stopImmediatePropagation();
              if (bgZoom == "#142443") {
                document.getElementById("zoom-btn").style.background = "#111";
                bgZoom = "#111";
                zoomMode = true;
              } else {
                document.getElementById("zoom-btn").style.background = "#142443";
                bgZoom = "#142443";
                zoomMode = false;
              }
            });
            
            $("#export-button").click(function(e){
              e.stopImmediatePropagation();
              download_table_as_csv('length-table', 'length-table1', 'length-table2', 'length-table3', 'length-table4');
            });
            $("#export-area").click(function(e){
              e.stopImmediatePropagation();
              download_table_as_csv('area-table', 'area-table1', 'area-table2', 'area-table3', 'area-table4');
            });
            $("#export-count").click(function(e){
              e.stopImmediatePropagation();
              download_table_as_csv('count-table', 'count-table1', 'count-table2', 'count-table3', 'count-table4');
            });
            $("#delete-button").click(function(e){
              e.stopImmediatePropagation();
              $(this.parentNode.parentNode).remove();
              for (let i=0; i < lenIds.length; i++){
                let obj = canvas.getObjects().find(obj => obj.id === lenIds[i]);
                canvas.remove(obj);
              }
            });
            $("#delete-area").click(function(e){
              e.stopImmediatePropagation();
              $(this.parentNode.parentNode).remove();
              for (let i=0; i < areaIds.length; i++){
                let obj = canvas.getObjects().find(obj => obj.id === areaIds[i]);
                canvas.remove(obj);
              }
            });
            $("#delete-count").click(function(e){
              e.stopImmediatePropagation();
              $(this.parentNode.parentNode).remove();
              for (let i=0; i < countIds.length; i++){
                let obj = canvas.getObjects().find(obj => obj.id === countIds[i]);
                canvas.remove(obj);
              }
            });

            $('#newLength').click(function(e){
              if(lengthAction == 0) {
                e.stopImmediatePropagation();
                var lengthClone = $(".container-fluid").find(".length-section").last().clone(true, true).show();
                $('.length-features').append(lengthClone);
                lengthAction++;
              } else if( lengthAction >= 1 && lengthAction <= 4) {
                e.stopImmediatePropagation();
                var lengthClone = $(".container-fluid").find(".length-section").last().clone(false, false).show();
                $('.length-features').append(lengthClone);
                lengthClone.find('[id]').each(function() {
                  var id = this.id;
                  $(this).attr('id', id + lengthId);           
                });
                //var exportBtn = "#export-button" + lengthId;
                //var lengthTable = "length-table" + lengthId;
                
                //$(exportBtn).click(function(e){
                  //e.stopImmediatePropagation();
                  //download_table_as_csv(lengthTable);
                //});

                $("#delete-button1").click(function(e){
                  e.stopImmediatePropagation();
                  $(this.parentNode.parentNode).remove();
                  for (let i=0; i < lenIds1.length; i++){
                    let obj = canvas.getObjects().find(obj => obj.id === lenIds1[i]);
                    canvas.remove(obj);
                  }
                });
                $("#delete-button2").click(function(e){
                  e.stopImmediatePropagation();
                  $(this.parentNode.parentNode).remove();
                  for (let i=0; i < lenIds2.length; i++){
                    let obj = canvas.getObjects().find(obj => obj.id === lenIds2[i]);
                    canvas.remove(obj);
                  }
                });
                $("#delete-button3").click(function(e){
                  e.stopImmediatePropagation();
                  $(this.parentNode.parentNode).remove();
                  for (let i=0; i < lenIds3.length; i++){
                    let obj = canvas.getObjects().find(obj => obj.id === lenIds3[i]);
                    canvas.remove(obj);
                  }
                });
                $("#delete-button4").click(function(e){
                  e.stopImmediatePropagation();
                  $(this.parentNode.parentNode).remove();
                  for (let i=0; i < lenIds4.length; i++){
                    let obj = canvas.getObjects().find(obj => obj.id === lenIds4[i]);
                    canvas.remove(obj);
                  }
                });
                lengthId++;
                lengthAction++;
              } else {
                e.stopImmediatePropagation();
                this.disabled = true;
                document.getElementById("newLength").style.background = "#E50000";
              }
            });

            $('#newArea').click(function(e){
              if(areaAction == 0) {
                e.stopImmediatePropagation();
                var areaClone = $(".container-fluid").find(".area-section").last().clone(true, true).show();
                $('.area-features').append(areaClone);
                areaAction++;
              } else if( areaAction >= 1 && areaAction <= 4) {
                e.stopImmediatePropagation();
                var areaClone = $(".container-fluid").find(".area-section").last().clone(false, false).show();
                $('.area-features').append(areaClone);
                areaClone.find('[id]').each(function() {
                  var id = this.id;
                  $(this).attr('id', id + areaId)           
                });

                var polyDraw = "#create-polygon" + areaId;

                $(polyDraw).click(function() {
                  prototypefabric.polygon.drawPolygon(); 
                });
                areaId++;
                areaAction++;
              } else {
                e.stopImmediatePropagation();
                this.disabled = true;
                document.getElementById("newArea").style.background = "#E50000";
              }
            });

            $('#newCount').click(function(e){
              if(countAction == 0) {
                e.stopImmediatePropagation();
                var countClone = $(".container-fluid").find(".count-section").last().clone(true, true).show();
                $('.count-features').append(countClone);
                countAction++;
              } else if( countAction >= 1 && countAction <= 4) {
                e.stopImmediatePropagation();
                var countClone = $(".container-fluid").find(".count-section").last().clone(false, false).show();
                $('.count-features').append(countClone);
                countClone.find('[id]').each(function() {
                  var id = this.id;
                  $(this).attr('id', id + countId)           
                });
        
                $("#delete-count1").click(function(e){
                  e.stopImmediatePropagation();
                  $(this.parentNode.parentNode).remove();
                  for (let i=0; i < countIds1.length; i++){
                    let obj = canvas.getObjects().find(obj => obj.id === countIds1[i]);
                    canvas.remove(obj);
                  }
                });
                $("#delete-count2").click(function(e){
                  e.stopImmediatePropagation();
                  $(this.parentNode.parentNode).remove();
                  for (let i=0; i < countIds2.length; i++){
                    let obj = canvas.getObjects().find(obj => obj.id === countIds2[i]);
                    canvas.remove(obj);
                  }
                });
                $("#delete-count3").click(function(e){
                  e.stopImmediatePropagation();
                  $(this.parentNode.parentNode).remove();
                  for (let i=0; i < countIds3.length; i++){
                    let obj = canvas.getObjects().find(obj => obj.id === countIds3[i]);
                    canvas.remove(obj);
                  }
                });
                $("#delete-count4").click(function(e){
                  e.stopImmediatePropagation();
                  $(this.parentNode.parentNode).remove();
                  for (let i=0; i < countIds4.length; i++){
                    let obj = canvas.getObjects().find(obj => obj.id === countIds4[i]);
                    canvas.remove(obj);
                  }
                });
                countId++;
                countAction++;
              } else {
                  e.stopImmediatePropagation();
                  this.disabled = true;
                  document.getElementById("newCount").style.background = "#E50000";
                }
            });
            
            document.getElementById('file').addEventListener("change", function (e) {
              var fileType = e.target.files[0].type;
              var file = e.target.files[0];
              var reader = new FileReader();
              reader.onload = function (f) {
                  var data = f.target.result;
                  if (fileType === 'image/png') { //check if png
                      fabric.Image.fromURL(data, function(img) {
                          img.scaleToWidth(canvas.getWidth());
                          canvas.add(img).renderAll();
                          $("#set").click(function(){
                            img.lockUniScaling = true;
                            img.lockMovementX = true;
                            img.lockMovementY = true;
                            img.lockScalingX = true;
                            img.lockScalingY = true;
                            img.selectable = false; 
                            img.evented = false;
                            img.setControlsVisibility({
                              mt: false, 
                              mb: false, 
                              ml: false, 
                              mr: false, 
                              bl: false,
                              br: false, 
                              tl: false, 
                              tr: false,
                              mtr: false
                            });
                            document.getElementById('calibrate-step1').style.display = "none";
                            document.getElementById('calibrate-step2').style.display = "block";
                            mode="draw";
                          });
                      });
                  } 
                  else {
                      fabric.Image.fromURL(data, function (img) {
                          img.scaleToWidth(canvas.getWidth());
                          canvas.add(img).renderAll();
                          canvas.setActiveObject(img);
                          $("#set").click(function(){
                            img.lockUniScaling = true;
                            img.lockMovementX = true;
                            img.lockMovementY = true;
                            img.lockScalingX = true;
                            img.lockScalingY = true;
                            img.selectable = false;
                            img.evented = false;
                            img.setControlsVisibility({
                              mt: false, 
                              mb: false, 
                              ml: false, 
                              mr: false, 
                              bl: false,
                              br: false, 
                              tl: false, 
                              tr: false,
                              mtr: false
                            });
                            document.getElementById('calibrate-step1').style.display = "none";
                            document.getElementById('calibrate-step2').style.display = "block";
                            mode="draw";
                          });
                      });
                  }
              };
              reader.readAsDataURL(file);
            });

            document.getElementById('file_modal').addEventListener("change", function (e) {
              var fileType = e.target.files[0].type;
              var file = e.target.files[0];
              var reader = new FileReader();
              reader.onload = function (f) {
                  var data = f.target.result;
                  if (fileType === 'image/png') { //check if png
                      fabric.Image.fromURL(data, function(img) {
                          img.scaleToWidth(canvas.getWidth());
                          canvas.add(img).renderAll();
                          $("#set").click(function(){
                            img.lockUniScaling = true;
                            img.lockMovementX = true;
                            img.lockMovementY = true;
                            img.lockScalingX = true;
                            img.lockScalingY = true;
                            img.selectable = false; 
                            img.evented = false;
                            img.setControlsVisibility({
                              mt: false, 
                              mb: false, 
                              ml: false, 
                              mr: false, 
                              bl: false,
                              br: false, 
                              tl: false, 
                              tr: false,
                              mtr: false
                            });
                            document.getElementById('calibrate-step1').style.display = "none";
                            document.getElementById('calibrate-step2').style.display = "block";
                            mode="draw";
                          });
                      });
                  } 
                  else {
                      fabric.Image.fromURL(data, function (img) {
                          img.scaleToWidth(canvas.getWidth());
                          canvas.add(img).renderAll();
                          canvas.setActiveObject(img);
                          $("#set").click(function(){
                            img.lockUniScaling = true;
                            img.lockMovementX = true;
                            img.lockMovementY = true;
                            img.lockScalingX = true;
                            img.lockScalingY = true;
                            img.selectable = false;
                            img.evented = false;
                            img.setControlsVisibility({
                              mt: false, 
                              mb: false, 
                              ml: false, 
                              mr: false, 
                              bl: false,
                              br: false, 
                              tl: false, 
                              tr: false,
                              mtr: false
                            });
                            document.getElementById('calibrate-step1').style.display = "none";
                            document.getElementById('calibrate-step2').style.display = "block";
                            mode="draw";
                          });
                      });
                  }
              };
              reader.readAsDataURL(file);
              document.getElementById('file').style.display = "none";
              document.getElementById('modal').style.display = "none";
            });

            
            canvas.on('mouse:wheel', function(opt) {
              if(zoomMode === true){
                var delta = opt.e.deltaY;
                var zoom = canvas.getZoom();
                zoom *= 0.999 ** delta;
                if (zoom > 20) zoom = 20;
                if (zoom < 0.01) zoom = 0.01;
                canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
                opt.e.preventDefault();
                opt.e.stopPropagation();
                var vpt = this.viewportTransform;
                if (zoom < 400 / 1000) {
                  vpt[4] = 200 - 1000 * zoom / 2;
                  vpt[5] = 200 - 1000 * zoom / 2;
                } else {
                  if (vpt[4] >= 0) {
                    vpt[4] = 0;
                  } else if (vpt[4] < canvas.getWidth() - 1000 * zoom) {
                    vpt[4] = canvas.getWidth() - 1000 * zoom;
                  }
                  if (vpt[5] >= 0) {
                    vpt[5] = 0;
                  } else if (vpt[5] < canvas.getHeight() - 1000 * zoom) {
                    vpt[5] = canvas.getHeight() - 1000 * zoom;
                  }
                }
              }
            });



            canvas.on('mouse:down', function (options) {
              if(mode=="draw"){
                if(tabMode == "area") {
                  if(options.target && options.target.id == pointArray[0].id){
                      prototypefabric.polygon.generatePolygon(pointArray);
                  }
                  if(polygonMode){
                      prototypefabric.polygon.addPoint(options);
                  }
                }
                else if(tabMode == "length") {
                  if(trigger == "1") {
                    custom_color = document.getElementById("colorPalette").style.color;
                    var random = Math.floor(Math.random() * (max - min + 1)) + min;
                    length_id = "len" + random;
                    isDown = true;
                    var pointer = canvas.getPointer(options.e);
                    var points = [ pointer.x, pointer.y, pointer.x, pointer.y ];
                    startx[temp] = pointer.x;
                    starty[temp] = pointer.y;
                    line = new fabric.Line(points, {
                      strokeWidth: 5,
                      fill: custom_color,
                      stroke: custom_color,
                      originX: 'center',
                      originY: 'center',
                      lockUniScaling: true,
                      lockMovementX: true,
                      lockMovementY: true,
                      lockScalingX: true,
                      lockScalingY: true,
                      id: length_id
                  });
                  canvas.add(line);
                  }
                  else {
                    canvas.forEachObject(function(options){
                      options.setCoords();
                    });
                  } 
                }
                else if(tabMode == "count"){
                  var tri_color = document.getElementById("colorPalette").style.color;
                  var random = Math.floor(Math.random() * (max - min + 1)) + min;
                  count_id = "tri" + random;
                  var triangle = new fabric.Triangle({
                    width: 20, 
                    height: 10,
                    angle: 180,
                    left: (options.e.layerX/canvas.getZoom()),
                    top: (options.e.layerY/canvas.getZoom()),
                    stroke: tri_color,
                    fill: tri_color,
                    originX: 'center',
                    originY: 'center',
                    selectable: false,
                    lockUniScaling: true,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    id: count_id
                  });    
                  canvas.add(triangle);    
                  canvas.renderAll();       
                }
                else if(tabMode == "calibrate") {
                  if(trigger == "1") {
                    isDown = true;
                    var pointer = canvas.getPointer(options.e);
                    var points = [ pointer.x, pointer.y, pointer.x, pointer.y ];
                    startx[temp] = pointer.x;
                    starty[temp] = pointer.y;
                    line = new fabric.Line(points, {
                      strokeWidth: 5,
                      fill: custom_color,
                      stroke: custom_color,
                      originX: 'center',
                      originY: 'center',
                      lockUniScaling: true,
                      lockMovementX: true,
                      lockMovementY: true,
                      lockScalingX: true,
                      lockScalingY: true
                  });
                  canvas.add(line);
                  }
                  else {
                    canvas.forEachObject(function(options){
                      options.setCoords();
                    });
                  } 
                }
              }
            });

            canvas.on('mouse:up', function (options) {
              if(mode=="draw"){
                if(tabMode == "length") {
                  isDown = false;
                  if(trigger == "1") {
                    if(lengthAction == 1){
                      var result = ((Calculate.lineLength(startx[temp], starty[temp], endx[temp], endy[temp])/50) * calibrated).toFixed(2);
                      var objectType = document.getElementById("length-type").value;
                      var costRate =  document.getElementById("costrate").value;
                      var cost = (result * costRate);
                      costs.push((cost));
                      lenIds.push(length_id);
                      var del_id = "#" + length_id;
                      $("#length-table").append('<tr><td>' + objectType + '</td><td>' + result + '</td><td>' + "$" + costRate + '</td><td>' + "$" + cost.toFixed(2) + '</td><td><button class="table-del" id=' + length_id + '>X</button></td></tr>');

                      $("#update-length").click(function(e){
                        e.stopImmediatePropagation();
                        costs = [];
                        costRate =  document.getElementById("costrate").value;
                        objectType = document.getElementById("length-type").value;
                        var len_tab = document.getElementById('length-table');
                        for (var i = 1, row; row = len_tab.rows[i]; i++) {
                          var cell_val = row.cells[1].innerHTML;
                          var updated = (costRate * cell_val).toFixed(2);
                          updated = parseFloat(updated);
                          row.cells[0].innerHTML = objectType;
                          row.cells[2].innerHTML = "$" + costRate;
                          row.cells[3].innerHTML = "$" + updated;
                          costs.push(updated);
                        }
                        var total = costs.reduce((a, b) => a + b, 0).toFixed(2);
                        document.getElementById('total-result').innerHTML = total;
                      });
                        
                      $(del_id).click(function(e){
                        e.stopImmediatePropagation();
                        $(this).each(function() {
                          var cell = $(this).closest("tr").find("td").eq(3).html();
                          cell = cell.replace(/\$/g, '');
                          cell = parseFloat(cell);
                          var index = costs.indexOf(cell);
                          costs.splice(index, 1);
                          var total = costs.reduce((a, b) => a + b, 0).toFixed(2);
                          document.getElementById('total-result').innerHTML = total;
                        });
                        $(this).closest("tr").remove();
                        let obj = canvas.getObjects().find(obj => obj.id === this.id);
                        canvas.remove(obj);
                      });
                      var total = costs.reduce((a, b) => a + b, 0).toFixed(2);
                      document.getElementById('total-result').innerHTML = total;

                    } else if(lengthAction > 1){

                      if (lengthAction == 2){
                        var costrateId = "costrate" + 1;
                        var lengthType = "length-type" + 1;
                        var lengthTableId = "#length-table" + 1;
                        var updateTableId = "length-table" + 1;
                        var resultId = "total-result" + 1;
                        var updateId = "#update-length" + 1;
                        var costArray = costs1;
                        lenIds1.push(length_id);
                      } else if (lengthAction == 3){
                        var costrateId = "costrate" + 2;
                        var lengthType = "length-type" + 2;
                        var lengthTableId = "#length-table" + 2;
                        var updateTableId = "length-table" + 2;
                        var resultId = "total-result" + 2;
                        var updateId = "#update-length" + 2;
                        var costArray = costs2;
                        lenIds2.push(length_id);
                      } else if (lengthAction == 4){
                        var costrateId = "costrate" + 3;
                        var lengthType = "length-type" + 3;
                        var lengthTableId = "#length-table" + 3;
                        var updateTableId = "length-table" + 3;
                        var resultId = "total-result" + 3;
                        var updateId = "#update-length" + 3;
                        var costArray = costs3;
                        lenIds3.push(length_id);
                      } else if (lengthAction == 5){
                        var costrateId = "costrate" + 4;
                        var lengthType = "length-type" + 4;
                        var lengthTableId = "#length-table" + 4;
                        var updateTableId = "length-table" + 4;
                        var resultId = "total-result" + 4;
                        var updateId = "#update-length" + 4;
                        var costArray = costs4;
                        lenIds4.push(length_id);
                      }
                      var result = ((Calculate.lineLength(startx[temp], starty[temp], endx[temp], endy[temp])/50) * calibrated).toFixed(2);
                      var objectType = document.getElementById(lengthType).value;
                      var costRate =  document.getElementById(costrateId).value;
                      var cost = (result * costRate);
                      costArray.push((cost));
                      var del_id = "#" + length_id;
                      $(lengthTableId).append('<tr><td>' + objectType + '</td><td>' + result + '</td><td>' + "$" + costRate + '</td><td>' + "$" + cost.toFixed(2) + '</td><td><button class="table-del" id=' + length_id + '>X</button></td></tr>');

                      $(updateId).click(function(e){
                        e.stopImmediatePropagation();
                        if (lengthAction == 2){
                          costs1 = [];
                          costArray = costs1;
                        } else if (lengthAction == 3){
                          costs2 = [];
                          costArray = costs2;
                        } else if (lengthAction == 4){
                          costs3 = [];
                          costArray = costs3;
                        } else if (lengthAction == 5){
                          costs4 = [];
                          costArray = costs4;
                        }
                        costRate =  document.getElementById(costrateId).value;
                        objectType = document.getElementById(lengthType).value;
                        var len_tab = document.getElementById(updateTableId);
                        for (var i = 1, row; row = len_tab.rows[i]; i++) {
                          var cell_val = row.cells[1].innerHTML;
                          var updated = (costRate * cell_val).toFixed(2);
                          updated = parseFloat(updated);
                          row.cells[0].innerHTML = objectType;
                          row.cells[2].innerHTML = "$" + costRate;
                          row.cells[3].innerHTML = "$" + updated;
                          costArray.push(updated);
                        }
                        var total = costArray.reduce((a, b) => a + b, 0).toFixed(2);
                        document.getElementById(resultId).innerHTML = total;
                      });
                
                      $(del_id).click(function(e){
                        e.stopImmediatePropagation();
                        $(this).each(function() {
                          var cell = $(this).closest("tr").find("td").eq(3).html();
                          cell = cell.replace(/\$/g, '');
                          cell = parseFloat(cell);
                          let costArrays = [costs1, costs2, costs3, costs4];
                          var costArrayTotal = [];
                          for(let i = 0; i < costArrays.length; i++) {
                            for(let j = 0; j < costArrays[i].length; j++) {
                              if(costArrays[i][j] == cell) {
                                costArrayTotal = costArrays[i];
                                if(costArrayTotal == costs1) {
                                  var resultIdTotal = "total-result" + 1;
                                } else if(costArrayTotal == costs2) {
                                  var resultIdTotal = "total-result" + 2;
                                } else if(costArrayTotal == costs3) {
                                  var resultIdTotal = "total-result" + 3;
                                } else if(costArrayTotal == costs3) {
                                  var resultIdTotal = "total-result" + 4;
                                }
                              }
                            }
                          }
                          var index = costArrayTotal.indexOf(cell);
                          costArrayTotal.splice(index, 1);
                          var total = costArrayTotal.reduce((a, b) => a + b, 0).toFixed(2);
                          document.getElementById(resultIdTotal).innerHTML = total;
                        });
                        $(this).closest("tr").remove();
                        let obj = canvas.getObjects().find(obj => obj.id === this.id);
                        canvas.remove(obj);
                      });
                      var total = costArray.reduce((a, b) => a + b, 0).toFixed(2);
                      document.getElementById(resultId).innerHTML = total;
                    } 
                  }
                }
                else if(tabMode == "count"){
                  if(countAction == 1){
                    var objectType = document.getElementById("count-type").value;
                    var cost =  document.getElementById("costcount").value;
                    cost = parseFloat(cost);
                    count.push(objectType);
                    countcosts.push(cost);
                    countIds.push(count_id);
                    var counter = 0;

                    for(let i=0; i < count.length; i++) {
                      if(count[i] == objectType) {
                        counter = counter + 1;
                      }
                    }
                    var del_id = "#" + count_id;
                    $("#count-table").append('<tr><td>' + objectType + '</td><td>' + counter + '</td><td>' + "$" + cost.toFixed(2) + '</td><td><button class="table-del" id=' + count_id + '>X</button></td></tr>');
                    $(del_id).click(function(e){
                      e.stopImmediatePropagation();
                      $(this).each(function() {
                        var cell = $(this).closest("tr").find("td").eq(2).html();
                        cell = cell.replace(/\$/g, '');
                        cell = parseFloat(cell);
                        var index = countcosts.indexOf(cell);
                        countcosts.splice(index, 1);
                        var total = countcosts.reduce((a, b) => a + b, 0).toFixed(2);
                        document.getElementById('count-total').innerHTML = total;
                      });
                      $(this).closest("tr").remove();
                      let obj = canvas.getObjects().find(obj => obj.id === this.id);
                      canvas.remove(obj);
                    });
                    var total = countcosts.reduce((a, b) => a + b, 0).toFixed(2);
                    document.getElementById('count-total').innerHTML = total;

                  } else if(countAction > 1){

                      if (countAction == 2){
                        var countType = "count-type" + 1;
                        var countTableId = "#count-table" + 1;
                        var countArray = count1;
                        var countCostArr = countcosts1;
                        var costCount = "costcount1";
                        var resultId = "count-total" + 1;
                        countIds1.push(count_id);
                      } else if (countAction == 3){
                        var countType = "count-type" + 2;
                        var countTableId = "#count-table" + 2;
                        var countArray = count2;
                        var countCostArr = countcosts2;
                        var costCount = "costcount2";
                        var resultId = "count-total" + 2;
                        countIds2.push(count_id);
                      } else if (countAction == 4){
                        var countType = "count-type" + 3;
                        var countTableId = "#count-table" + 3;
                        var countArray = count3;
                        var countCostArr = countcosts3;
                        var costCount = "costcount3";
                        var resultId = "count-total" + 3;
                        countIds3.push(count_id);
                      } else if (countAction == 5){
                        var countType = "count-type" + 4;
                        var countTableId = "#count-table" + 4;
                        var countArray = count4;
                        var countCostArr = countcosts4;
                        countIds4.push(count_id);
                        var costCount = "costcount4";
                        var resultId = "count-total" + 4;
                      }
                      var objectType = document.getElementById(countType).value;
                      var cost =  document.getElementById(costCount).value;
                      cost = parseFloat(cost);
                      countCostArr.push(cost);
                      countArray.push(objectType);
                      var counter = 0;
                      for(let i=0; i < countArray.length; i++) {
                        if(countArray[i] == objectType) {
                          counter = counter + 1;
                        }
                      }
                      var del_id1 = "#" + count_id;
                      $(countTableId).append('<tr><td>' + objectType + '</td><td>' + counter + '</td><td>' + "$" + cost.toFixed(2) + '</td><td><button class="table-del" id=' + count_id + '>X</button></td></tr>');
                      $(del_id1).click(function(e){
                        e.stopImmediatePropagation();
                        $(this).each(function() {
                          var cell = $(this).closest("tr").find("td").eq(2).html();
                          cell = cell.replace(/\$/g, '');
                          cell = parseFloat(cell);
                          let costArrays = [countcosts1, countcosts2, countcosts3, countcosts4];
                          var costArrayTotal = [];
                          for(let i = 0; i < costArrays.length; i++) {
                            for(let j = 0; j < costArrays[i].length; j++) {
                              if(costArrays[i][j] == cell) {
                                costArrayTotal = costArrays[i];
                                if(costArrayTotal == countcosts1) {
                                  var resultIdTotal = "count-total" + 1;
                                } else if(costArrayTotal == countcosts2) {
                                  var resultIdTotal = "count-total" + 2;
                                } else if(costArrayTotal == countcosts3) {
                                  var resultIdTotal = "count-total" + 3;
                                } else if(costArrayTotal == countcosts3) {
                                  var resultIdTotal = "count-total" + 4;
                                }
                              }
                            }
                          }
                          var index = costArrayTotal.indexOf(cell);
                          costArrayTotal.splice(index, 1);
                          var total = costArrayTotal.reduce((a, b) => a + b, 0).toFixed(2);
                          document.getElementById(resultIdTotal).innerHTML = total;
                        });
                        $(this).closest("tr").remove();
                        let obj = canvas.getObjects().find(obj => obj.id === this.id);
                        canvas.remove(obj);
                      });
                      var count_total = countCostArr.reduce((a, b) => a + b, 0).toFixed(2);
                      document.getElementById(resultId).innerHTML = count_total;
                    }

                } else if(tabMode == "calibrate") {
                  isDown = false;
                  var original = (Calculate.lineLength(startx[temp], starty[temp], endx[temp], endy[temp])/50).toFixed(2);
                  
                  $("#done-step2").click(function(){
                    document.getElementById('calibrate-step2').style.display = "none";
                    document.getElementById('calibrate-step3').style.display = "block";
                    this.disabled = true;
                  });
                  $("#done-step3").click(function(){
                    var userLength =  document.getElementById("user-length").value;
                    calibrated = (userLength / original);
                    canvas.remove(line);
                    document.getElementById('calibrate-step3').style.display = "none";
                    document.getElementById('calibrate-step4').style.display = "block";
                    this.disabled = true;
                  });
                }
              }
            });

            canvas.on('mouse:move', function (options) {
              if(mode=="draw"){
                if(tabMode == "area") {
                  if(activeLine && activeLine.class == "line"){
                      var pointer = canvas.getPointer(options.e);
                      activeLine.set({ x2: pointer.x, y2: pointer.y });
      
                      var points = activeShape.get("points");
                      points[pointArray.length] = {
                          x:pointer.x,
                          y:pointer.y
                      }
                      activeShape.set({
                          points: points
                      });
                      canvas.renderAll();
                  }
                  canvas.renderAll();
              }
              else if(tabMode == "length") {
                canvas.remove(text);
                canvas.renderAll();
                if (!isDown) return;
                var pointer = canvas.getPointer(options.e);
                line.set({ x2: pointer.x, y2: pointer.y });
                endx[temp] = pointer.x;
                endy[temp] = pointer.y;

                if(trigger == "1") {
                  var px = ((Calculate.lineLength(startx[temp], starty[temp], endx[temp], endy[temp])/50) * calibrated).toFixed(2);
                  text = new fabric.Text('Length ' + px, { left: endx[temp], top: endy[temp], fontSize: 14.5, fill: 'red' });					
                  canvas.add(text);	
                }
                canvas.renderAll();
              }
              else if(tabMode == "calibrate") {
                canvas.remove(text);
                canvas.renderAll();
                if (!isDown) return;
                var pointer = canvas.getPointer(options.e);
                line.set({ x2: pointer.x, y2: pointer.y });
                endx[temp] = pointer.x;
                endy[temp] = pointer.y;
              }
            }
          });
        }
    };

    function Area (points) {
        let total = 0;
        for (let i = 0; i < points.length; i++) {
            const addX = points[i].x;
            const addY = points[i === points.length - 1 ? 0 : i + 1].y;
            const subX = points[i === points.length - 1 ? 0 : i + 1].x;
            const subY = points[i].y;
            total += (addX * addY * 0.5) - (subX * subY * 0.5);
        }
        return Math.abs(total);
    }

    var Calculate = {
      lineLength:function(x1, y1, x2, y2){
				return Math.sqrt(Math.pow(x2*1-x1*1, 2)+Math.pow(y2*1-y1*1, 2));
			}
    }

    prototypefabric.polygon = {
        drawPolygon : function() {
            polygonMode = true;
            pointArray = new Array();
            lineArray = new Array();
            activeLine = "";
        },
        addPoint : function(options) {
            var random = Math.floor(Math.random() * (max - min + 1)) + min;
            var id = new Date().getTime() + random;
            var circle = new fabric.Circle({
                radius: 5,
                fill: '#ffffff',
                stroke: '#333333',
                strokeWidth: 0.5,
                left: (options.e.layerX/canvas.getZoom()),
                top: (options.e.layerY/canvas.getZoom()),
                selectable: false,
                hasBorders: false,
                hasControls: false,
                originX:'center',
                originY:'center',
                id:id,
                objectCaching:false
            });
            if(pointArray.length == 0){
                circle.set({
                    fill:'red'
                })
            }
            var points = [(options.e.layerX/canvas.getZoom()),(options.e.layerY/canvas.getZoom()),(options.e.layerX/canvas.getZoom()),(options.e.layerY/canvas.getZoom())];
            var line = new fabric.Line(points, {
                strokeWidth: 2,
                fill: '#999999',
                stroke: '#999999',
                class:'line',
                originX:'center',
                originY:'center',
                selectable: false,
                hasBorders: false,
                hasControls: false,
                evented: false,
                objectCaching:false
            });
            if(activeShape){
                var pos = canvas.getPointer(options.e);
                var points = activeShape.get("points");
                points.push({
                    x: pos.x,
                    y: pos.y
                });
                var polygon = new fabric.Polygon(points,{
                    stroke:'#333333',
                    strokeWidth:1,
                    fill: '#cccccc',
                    opacity: 0.3,
                    selectable: false,
                    hasBorders: false,
                    hasControls: false,
                    evented: false,
                    objectCaching:false
                });
                canvas.remove(activeShape);
                canvas.add(polygon);
                activeShape = polygon;
                canvas.renderAll();
            }
            else{
                var polyPoint = [{x:(options.e.layerX/canvas.getZoom()),y:(options.e.layerY/canvas.getZoom())}];
                var polygon = new fabric.Polygon(polyPoint,{
                    stroke:'#333333',
                    strokeWidth:1,
                    fill: '#cccccc',
                    opacity: 0.3,
                    selectable: false,
                    hasBorders: false,
                    hasControls: false,
                    evented: false,
                    objectCaching:false
                });
                activeShape = polygon;
                canvas.add(polygon);
            }
            activeLine = line;

            pointArray.push(circle);
            lineArray.push(line);

            canvas.add(line);
            canvas.add(circle);
            canvas.selection = false;
        },
        generatePolygon : function(pointArray){
            var points = new Array();
            $.each(pointArray,function(index,point){
                points.push({
                    x:point.left,
                    y:point.top
                });
                canvas.remove(point);
            });
            $.each(lineArray,function(index,line){
                canvas.remove(line);
            });
            canvas.remove(activeShape).remove(activeLine);
            var random = Math.floor(Math.random() * (max - min + 1)) + min;
            area_id = "area" + random;
            var area_color = document.getElementById("colorPalette").style.color;
            var polygon = new fabric.Polygon(points,{
                stroke:'#333333',
                strokeWidth:0.5,
                fill: area_color,
                opacity: 0.45,
                hasBorders: false,
                hasControls: false,
                id: area_id,
                lockMovementX: true,
                lockMovementY: true,
                selectable: false
            });
            canvas.add(polygon);

            activeLine = null;
            activeShape = null;
            polygonMode = false;
            canvas.selection = true;
            var calibrated_area = calibrated * calibrated;
              
            if(areaAction == 1){
              var del_id = "#" + area_id;
              areaIds.push(area_id);
              var a = ((Area(points)/2410) * calibrated_area);
              var objectType = document.getElementById("area-type").value;
              var costRate =  document.getElementById("costarea").value;
              var cost = (a * costRate).toFixed(2);
              cost = parseFloat(cost);
              arcosts.push((cost));
              $("#area-table").append('<tr><td>' + objectType + '</td><td>' + a.toFixed(2) + '</td><td>' + "$" + costRate + '</td><td>' + "$" + cost + '</td><td><button class="table-del" id=' + area_id + '>X</button></td></tr>');

              $("#update-length").click(function(e){
                e.stopImmediatePropagation();
              });

              $("#update-area").click(function(e){
                e.stopImmediatePropagation();
                arcosts = [];
                costRate =  document.getElementById("costarea").value;
                objectType = document.getElementById("area-type").value;
                var len_tab = document.getElementById('area-table');
                for (var i = 1, row; row = len_tab.rows[i]; i++) {
                  var cell_val = row.cells[1].innerHTML;
                  var updated = (costRate * cell_val).toFixed(2);
                  updated = parseFloat(updated);
                  row.cells[0].innerHTML = objectType;
                  row.cells[2].innerHTML = "$" + costRate;
                  row.cells[3].innerHTML = "$" + updated;
                  arcosts.push(updated);
                }
                var total = arcosts.reduce((a, b) => a + b, 0).toFixed(2);
                document.getElementById('area-total').innerHTML = total;
              });

              $(del_id).click(function(e){
                e.stopImmediatePropagation();
                $(this).each(function() {
                  var cell = $(this).closest("tr").find("td").eq(3).html();
                  cell = cell.replace(/\$/g, '');
                  cell = parseFloat(cell);
                  var index = arcosts.indexOf(cell);
                  arcosts.splice(index, 1);
                  var total = arcosts.reduce((a, b) => a + b, 0).toFixed(2);
                  document.getElementById('area-total').innerHTML = total;
                });
                $(this).closest("tr").remove();
                let obj = canvas.getObjects().find(obj => obj.id === this.id);
                canvas.remove(obj);
              });

              var total = arcosts.reduce((a, b) => a + b, 0).toFixed(2);
              document.getElementById('area-total').innerHTML = total;

            } else if (areaAction == 2){
              var del_id1 = "#" + area_id;
              areaIds1.push(area_id);
              var a1 = ((Area(points)/2410) * calibrated_area);
              var objectType = document.getElementById("area-type1").value;
              var costRate =  document.getElementById("costarea1").value;
              var cost1 = (a1 * costRate).toFixed(2);
              cost1 = parseFloat(cost1);
              arcosts1.push((cost1));
              $("#area-table1").append('<tr><td>' + objectType + '</td><td>' + a1.toFixed(2) + '</td><td>' + "$" + costRate + '</td><td>' + "$" + cost1 + '</td><td><button class="table-del" id=' + area_id + '>X</button></td></tr>');

              $("#update-area1").click(function(e){
                e.stopImmediatePropagation();
                arcosts1 = [];
                costRate =  document.getElementById("costarea1").value;
                objectType = document.getElementById("area-type1").value;
                var len_tab = document.getElementById('area-table1');
                for (var i = 1, row; row = len_tab.rows[i]; i++) {
                  var cell_val = row.cells[1].innerHTML;
                  var updated = (costRate * cell_val).toFixed(2);
                  updated = parseFloat(updated);
                  row.cells[0].innerHTML = objectType;
                  row.cells[2].innerHTML = "$" + costRate;
                  row.cells[3].innerHTML = "$" + updated;
                  arcosts1.push(updated);
                }
                var total = arcosts1.reduce((a, b) => a + b, 0).toFixed(2);
                document.getElementById('area-total1').innerHTML = total;
              });

              $(del_id1).click(function(e){
                e.stopImmediatePropagation();
                $(this).each(function() {
                  var cell = $(this).closest("tr").find("td").eq(3).html();
                  cell = cell.replace(/\$/g, '');
                  cell = parseFloat(cell);
                  var index = arcosts1.indexOf(cell);
                  arcosts1.splice(index, 1);
                  var total = arcosts1.reduce((a, b) => a + b, 0).toFixed(2);
                  document.getElementById('area-total1').innerHTML = total;
                });
                $(this).closest("tr").remove();
                let obj = canvas.getObjects().find(obj => obj.id === this.id);
                canvas.remove(obj);
              });

              var total = arcosts1.reduce((a, b) => a + b, 0).toFixed(2);
              document.getElementById('area-total1').innerHTML = total;

              $("#delete-area1").click(function(e){
                e.stopImmediatePropagation();
                $(this.parentNode.parentNode).remove();
                for (let i=0; i < areaIds1.length; i++){
                  let obj = canvas.getObjects().find(obj => obj.id === areaIds1[i]);
                  canvas.remove(obj);
                }
              });

            } else if (areaAction == 3){
              var del_id2 = "#" + area_id;
              areaIds2.push(area_id);
              var a2 = ((Area(points)/2410) * calibrated_area);
              var objectType = document.getElementById("area-type2").value;
              var costRate =  document.getElementById("costarea2").value;
              var cost2 = (a2 * costRate).toFixed(2);
              cost2 = parseFloat(cost2);
              arcosts2.push((cost2));
              $("#area-table2").append('<tr><td>' + objectType + '</td><td>' + a2.toFixed(2) + '</td><td>' + "$" + costRate + '</td><td>' + "$" + cost2 + '</td><td><button class="table-del" id=' + area_id + '>X</button></td></tr>');

              $("#update-area2").click(function(e){
                e.stopImmediatePropagation();
                arcosts2 = [];
                costRate =  document.getElementById("costarea2").value;
                objectType = document.getElementById("area-type2").value;
                var len_tab = document.getElementById('area-table2');
                for (var i = 1, row; row = len_tab.rows[i]; i++) {
                  var cell_val = row.cells[1].innerHTML;
                  var updated = (costRate * cell_val).toFixed(2);
                  updated = parseFloat(updated);
                  row.cells[0].innerHTML = objectType;
                  row.cells[2].innerHTML = "$" + costRate;
                  row.cells[3].innerHTML = "$" + updated;
                  arcosts2.push(updated);
                }
                var total = arcosts2.reduce((a, b) => a + b, 0).toFixed(2);
                document.getElementById('area-total2').innerHTML = total;
              });

              $(del_id2).click(function(e){
                e.stopImmediatePropagation();
                $(this).each(function() {
                  var cell = $(this).closest("tr").find("td").eq(3).html();
                  cell = cell.replace(/\$/g, '');
                  cell = parseFloat(cell);
                  var index = arcosts2.indexOf(cell);
                  arcosts2.splice(index, 1);
                  var total = arcosts2.reduce((a, b) => a + b, 0).toFixed(2);
                  document.getElementById('area-total2').innerHTML = total;
                });
                $(this).closest("tr").remove();
                let obj = canvas.getObjects().find(obj => obj.id === this.id);
                canvas.remove(obj);
              });

              var total = arcosts2.reduce((a, b) => a + b, 0).toFixed(2);
              document.getElementById('area-total2').innerHTML = total;

              $("#delete-area2").click(function(e){
                e.stopImmediatePropagation();
                $(this.parentNode.parentNode).remove();
                for (let i=0; i < areaIds2.length; i++){
                  let obj = canvas.getObjects().find(obj => obj.id === areaIds2[i]);
                  canvas.remove(obj);
                }
              });

            } else if (areaAction == 4){
              var del_id3 = "#" + area_id;
              areaIds3.push(area_id);
              var a3 = ((Area(points)/2410) * calibrated_area);
              var objectType = document.getElementById("area-type3").value;
              var costRate =  document.getElementById("costarea3").value;
              var cost3 = (a3 * costRate).toFixed(2);
              cost3 = parseFloat(cost3);
              arcosts3.push((cost3));
              $("#area-table3").append('<tr><td>' + objectType + '</td><td>' + a3.toFixed(2) + '</td><td>' + "$" + costRate + '</td><td>' + "$" + cost3 + '</td><td><button class="table-del" id=' + area_id + '>X</button></td></tr>');

              $("#update-area3").click(function(e){
                e.stopImmediatePropagation();
                arcosts3 = [];
                costRate =  document.getElementById("costarea3").value;
                objectType = document.getElementById("area-type3").value;
                var len_tab = document.getElementById('area-table3');
                for (var i = 1, row; row = len_tab.rows[i]; i++) {
                  var cell_val = row.cells[1].innerHTML;
                  var updated = (costRate * cell_val).toFixed(2);
                  updated = parseFloat(updated);
                  row.cells[0].innerHTML = objectType;
                  row.cells[2].innerHTML = "$" + costRate;
                  row.cells[3].innerHTML = "$" + updated;
                  arcosts3.push(updated);
                }
                var total = arcosts3.reduce((a, b) => a + b, 0).toFixed(2);
                document.getElementById('area-total3').innerHTML = total;
              });

              $(del_id3).click(function(e){
                e.stopImmediatePropagation();
                $(this).each(function() {
                  var cell = $(this).closest("tr").find("td").eq(3).html();
                  cell = cell.replace(/\$/g, '');
                  cell = parseFloat(cell);
                  var index = arcosts3.indexOf(cell);
                  arcosts3.splice(index, 1);
                  var total = arcosts3.reduce((a, b) => a + b, 0).toFixed(2);
                  document.getElementById('area-total3').innerHTML = total;
                });
                $(this).closest("tr").remove();
                let obj = canvas.getObjects().find(obj => obj.id === this.id);
                canvas.remove(obj);
              });

              var total = arcosts3.reduce((a, b) => a + b, 0).toFixed(2);
              document.getElementById('area-total3').innerHTML = total;

              $("#delete-area3").click(function(e){
                e.stopImmediatePropagation();
                $(this.parentNode.parentNode).remove();
                for (let i=0; i < areaIds3.length; i++){
                  let obj = canvas.getObjects().find(obj => obj.id === areaIds3[i]);
                  canvas.remove(obj);
                }
              });

            }
            else if (areaAction == 5){
              var del_id4 = "#" + area_id;
              areaIds4.push(area_id);
              var a4 = ((Area(points)/2410) * calibrated_area);
              var objectType = document.getElementById("area-type4").value;
              var costRate =  document.getElementById("costarea4").value;
              var cost4 = (a4 * costRate).toFixed(2);
              cost4 = parseFloat(cost4);
              arcosts4.push((cost4));
              $("#area-table4").append('<tr><td>' + objectType + '</td><td>' + a4.toFixed(2) + "m²" + '</td><td>' + "$" + costRate + '</td><td>' + "$" + cost4 + '</td><td><button class="table-del" id=' + area_id + '>X</button></td></tr>');

              $("#update-area4").click(function(e){
                e.stopImmediatePropagation();
                arcosts4 = [];
                costRate =  document.getElementById("costarea4").value;
                objectType = document.getElementById("area-type4").value;
                var len_tab = document.getElementById('area-table4');
                for (var i = 1, row; row = len_tab.rows[i]; i++) {
                  var cell_val = row.cells[1].innerHTML;
                  var updated = (costRate * cell_val).toFixed(2);
                  updated = parseFloat(updated);
                  row.cells[0].innerHTML = objectType;
                  row.cells[2].innerHTML = "$" + costRate;
                  row.cells[3].innerHTML = "$" + updated;
                  arcosts4.push(updated);
                }
                var total = arcosts4.reduce((a, b) => a + b, 0).toFixed(2);
                document.getElementById('area-total4').innerHTML = total;
              });

              $(del_id4).click(function(e){
                e.stopImmediatePropagation();
                $(this).each(function() {
                  var cell = $(this).closest("tr").find("td").eq(3).html();
                  cell = cell.replace(/\$/g, '');
                  cell = parseFloat(cell);
                  var index = arcosts4.indexOf(cell);
                  arcosts4.splice(index, 1);
                  var total = arcosts4.reduce((a, b) => a + b, 0).toFixed(2);
                  document.getElementById('area-total4').innerHTML = total;
                });
                $(this).closest("tr").remove();
                let obj = canvas.getObjects().find(obj => obj.id === this.id);
                canvas.remove(obj);
              });

              var total = arcosts4.reduce((a, b) => a + b, 0).toFixed(2);
              document.getElementById('area-total4').innerHTML = total;

              $("#delete-area4").click(function(e){
                e.stopImmediatePropagation();
                $(this.parentNode.parentNode).remove();
                for (let i=0; i < areaIds4.length; i++){
                  let obj = canvas.getObjects().find(obj => obj.id === areaIds4[i]);
                  canvas.remove(obj);
                }
              });
            }
          }
    };

    function download_table_as_csv(table_id, table_id1, table_id2, table_id3, table_id4, separator = ',') {
      // Select rows from table_id
      var rows = document.querySelectorAll('#' + table_id + ' tr');
      var rows1 = document.querySelectorAll('#' + table_id1 + ' tr');
      var rows2 = document.querySelectorAll('#' + table_id2 + ' tr');
      var rows3 = document.querySelectorAll('#' + table_id3 + ' tr');
      var rows4 = document.querySelectorAll('#' + table_id4 + ' tr');
      // Construct csv
      var csv = [];
      for (var i = 0; i < rows.length - 1; i++) {
          var row = [], cols = rows[i].querySelectorAll('th, td');
          for (var j = 0; j < cols.length - 1; j++) {
              // Clean innertext to remove multiple spaces and jumpline (break csv)
              var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
              data = data.replace(/"/g, '""');
              // Push escaped string
              row.push('"' + data + '"');
          }
          csv.push(row.join(separator));
      }
      for (var i = 0; i < rows1.length; i++) {
        var row1 = [], cols = rows1[i].querySelectorAll('td');
        for (var j = 0; j < cols.length - 1; j++) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            data = data.replace(/"/g, '""');
            // Push escaped string
            row1.push('"' + data + '"');
        }
        csv.push(row1.join(separator));
      }
      for (var i = 0; i < rows2.length; i++) {
        var row2 = [], cols = rows2[i].querySelectorAll('td');
        for (var j = 0; j < cols.length - 1; j++) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            data = data.replace(/"/g, '""');
            // Push escaped string
            row2.push('"' + data + '"');
        }
        csv.push(row2.join(separator));
      }
      for (var i = 0; i < rows3.length; i++) {
        var row3 = [], cols = rows3[i].querySelectorAll('td');
        for (var j = 0; j < cols.length - 1; j++) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            data = data.replace(/"/g, '""');
            // Push escaped string
            row3.push('"' + data + '"');
        }
        csv.push(row3.join(separator));
      }
      for (var i = 0; i < rows4.length; i++) {
        var row4 = [], cols = rows4[i].querySelectorAll('td');
        for (var j = 0; j < cols.length - 1; j++) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            data = data.replace(/"/g, '""');
            // Push escaped string
            row4.push('"' + data + '"');
        }
        csv.push(row4.join(separator));
      }
      var csv_string = csv.join('\n');
      // Download it
      var filename = 'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
      var link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }


  return (
    <>
        <div className="container-fluid" style={rowStyle}>
            <div className="row" style={rowStyle}>
                <div className="column1">
                    <div className="container-fluid" style={paddingStyle}>
                      <h1>QuantiSpacer</h1>
                      <p>v1.1 beta - Currently in development</p>
                      <div className="main-features">
                        <button className="main-btn" id="draw"><FaPencilAlt /><span class="tooltiptext">Draw</span></button>
                        <button className="main-btn" id="select"><FaMousePointer /><span class="tooltiptext">Select</span></button>
                        <button className="main-btn" id="btn-toggle"><FaPalette id="colorPalette" /><input type='text' id="custom-color" /><span class="tooltiptext">Color</span></button>
                        <button className="main-btn" id="zoom-btn"><FaSearchPlus /><span class="tooltiptext">Zoom</span></button>
                      </div>
                      <div id="modal">
                        <div class="modal_content">
                            <button id="modal-btn">X</button>
                            <h1>QuantiSpacer</h1>
                            <p>v1.1 beta - Currently in development</p>
                            <h2 class="modal-title">Upload your drawing</h2>
                            <input id="file_modal" type="file"></input>
                        </div>
                      </div>
                    </div>
                    <Tabs className="tab-bar" defaultActiveKey="calibrate" id="tabs" onSelect={handleSelect}>
                    <Tab eventKey="calibrate" title="Calibrate">
                      <div className="upload-features">
                        <div id="calibrate-step1">
                          <input id="file" type="file"></input>
                          <p>Click below to begin calibrating your drawing.</p>
                          <button className="set-btn" id="set">Calibrate</button>
                        </div>
                        <div id="calibrate-step2" style={hideDiv}>
                          <p>Trace a line over an element in your drawing that has a known dimension, such as a wall or scale stamp.</p>
                          <p>Your final trace-line will be used.</p>
                          <button id="done-step2">Done</button>
                        </div>
                        <div id="calibrate-step3" style={hideDiv}>
                          <p>Enter the length of your trace-line as specified on your drawing</p>
                          <label for="calibrator" id="calibrator-label">Length (metres):</label>
                          <input type="number" id="user-length" name="calibrator" min="0" max="100"></input>
                          <button id="done-step3">Done</button>
                        </div>
                        <div id="calibrate-step4" style={hideDiv}>
                          <p>You are now ready to begin measuring!</p>
                          <p>Refer to the 'Tutorial' tab to learn how to use QuantiSpacer</p>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="length" title="Length">
                    <div className="length-features">
                        <button id="newLength"> Add New Group</button>
                        <button className="export"><a id="export-button" href="#">Export</a></button>
                    </div>
                    </Tab>
                    <Tab eventKey="area" title="Area">
                      <div className="area-features">
                        <button id="newArea"> Add New Group</button>
                        <button className="export"><a id="export-area" href="#">Export</a></button>
                      </div>
                    </Tab>
                    <Tab eventKey="count" title="Count">
                    <div className="count-features">
                      <button id="newCount"> Add New Group</button>
                      <button className="export"><a id="export-count" href="#">Export</a></button>
                    </div>
                    </Tab>
                    <Tab eventKey="instructions" title="Tutorial">
                    <div className="instructions-page">
                        <div className="instructions">
                          <h3>Length:</h3>
                          <p>
                            1.	Select 'Draw', and 'Add New Group' to create a measure group.
                            <br/>
                            2.	Click and drag over the drawing to measure, results will appear in the table below.
                            <br/>
                            3.	To calculate the cost of measurement, input a cost rate into the 'Cost Rate' box.
                          </p>
                          <h3>Area:</h3>
                          <p>
                            1.	Select 'Draw', and 'Add New Group' to create an area group.
                            <br/>
                            2.	Click the drawing to add the first point, each additional click will add more points.
                            <br/>
                            3.	Re-select the first point to close your area shape/polygon.
                            <br/>
                            4.	Select 'New Area' to begin drawing a new area.
                            <br/>
                          </p>
                          <h3>Count:</h3>
                          <p>
                            1.	Select 'Draw', and 'Add New Group' to create a count group.
                            <br/>
                            2.	Add the object you are counting into the input box.
                            <br/>
                            3.	Click the drawing to add the first indicator, each additional click will add more indicators.
                            <br/>
                            4.	Counted objects will be displayed in the table, typing a new object in the input restarts the count.
                            <br/>
                          </p>
                        </div>
                      </div>
                    <div className="length-section" style={hideDiv}>
                      <div className="length-buttons">
                        <div className="length-input">
                          <label for="type-input" id="length-label">Name</label>
                          <input type="text" id="length-type" name="type-input"></input>
                        </div>
                        <div className="lengthcost-input">
                          <label for="quantity" id="costrate-label">Cost/m</label>
                          <input type="number" id="costrate" name="quantity" min="0" max="100" placeholder="0"></input>
                        </div>
                        <button id="delete-button">X</button>
                        <button id="update-length" className="update-btn">Update Table</button>
                      </div>
                      <table id="length-table">
                        <tr>
                          <th>Name</th>
                          <th>Length (m)</th>
                          <th>Cost/m</th>
                          <th>Cost</th>
                          <th></th>
                        </tr>
                      </table>
                      <p id="total-text">Total: $<span id="total-result"></span></p>
                      </div>
                      <div className="area-section" style={hideDiv}>
                        <div className="area-buttons">
                          <div className="area-input">
                            <label for="type-input" id="area-label">Name</label>
                            <input type="text" id="area-type" name="type-input"></input>
                          </div>
                          <div className="areacost-input">
                            <label for="quantity" id="costarea-label">Cost/m²</label>
                            <input type="number" id="costarea" name="quantity" min="0" max="100" placeholder="0"></input>
                          </div>
                          <button id="delete-area">X</button>
                          <button id="create-polygon" className="area-btn">New Area</button>
                          <button id="update-area" className="update-btn">Update Table</button>
                        </div>
                          <table id="area-table">
                            <tr>
                              <th>Name</th>
                              <th>Area (m²)</th>
                              <th>Cost/m²</th>
                              <th>Cost</th>
                              <th></th>
                            </tr>
                          </table>
                          <p id="total-text">Total: $<span id="area-total"></span></p>
                      </div>
                      <div className="count-section" style={hideDiv}>
                        <div className="count-buttons">
                          <div className="count-input">
                            <label for="type-input" id="count-label">Name</label>
                            <input type="text" id="count-type" name="type-input"></input>
                          </div>
                          <div className="costcount-input">
                          <label for="quantity" id="costcount-label">Cost</label>
                          <input type="number" id="costcount" name="quantity" min="0" max="100" placeholder="0"></input>
                        </div>
                          <button id="delete-count">X</button>
                        </div>
                        <table id="count-table">
                            <tr>
                              <th>Name</th>
                              <th>Count</th>
                              <th>Cost</th>
                              <th></th>
                            </tr>
                          </table>
                          <p id="total-text">Total: $<span id="count-total"></span></p>
                      </div>
                    </Tab>
                    </Tabs>
              </div>
                <div className="column2">
                  <canvas id = "c"></canvas>
                </div>
            </div>
            <div className="mobile" style={hideDiv}>
              <p id="mobile-text">QuantiSpacer Mobile Version coming soon!</p>
            </div>
        </div>
    </>
  ) 
}
