import React from 'react';
import './hero.css';
import Video2 from '../../videos/video2.mp4';

export const HeroServices = () => {
    return (
        <div className="hero-container">
            <div className="hero-bg">
                <video className="hero-vid" autoPlay loop muted src={Video2} type='video/mp4' />
            </div>
            <div className="hero-content">
                <h1>SERVICES</h1>
                <h2>Streamlined technical consulting with more efficiency and less bureaucracy.</h2>
            </div>
        </div>
    )
}