import React from 'react'
import './info.css';
import { InfoWrapper, InfoRow, Column1, TextWrapper, Subtitle, Column2 } from './infopagedata';

export const InfoAbout = () => {
    return (
        <>
            <div className="about-container">
                <InfoWrapper>
                    <InfoRow>
                        <Column1>
                            <TextWrapper>
                                <div className='about-us-logos'>
                                    <h1>CYBERFRONT</h1>
                                    <h2>MARKET WISDOM</h2>
                                    <h3>QuantiSpacer</h3>
                                    <h4>TECHNICAL</h4>
                                    <h5>SERVICES</h5>
                                </div>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <Subtitle>
                                CyberFront is a start-up created to reshape the paradigm of the tech industry. We aim to create a perfect and unified system of services and apps with a focus on functionality and ease of use.
                                <br/>
                                <br/>
                                The journey began when a team of experienced engineers felt the efficiency of their work was negatively impacted by the restrictive and overly bureaucratic nature of management in the construction industry.
                                <br/>
                                <br/>
                                CyberFront was built from the ground-up with the intent of streamlining its foundations in support of engineering services. This allows for operations with maximum efficiency and free of unnecessary restrictions.
                                <br/>
                                <br/>
                                We currently offer a line up of services and apps such as TECHNICAL SERVICES consultation, MARKET WISDOM and QuantiSpacer. We plan to expand and improve upon our line up regularly in the near future with new exciting and innovating projects and updates.
                                <br/>
                                <br/>
                                Thanks for accompanying us into this new frontier.
                            </Subtitle>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </div>
        </>
    );
}