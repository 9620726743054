import React from 'react';
import '../App.css';
import { HeroSection } from '../hero/hero';
import { InfoSection } from '../info/info';
import { homeObjOne, homeServices } from '../data';
import { InfoQs } from '../info/infoqs';
import Tiles from '../tiles/tiles';
import Footer from '../footer/Footer';


export default function Home () {
    return (
        <>
            <div className="main">
                <HeroSection />
                <InfoSection {...homeObjOne} />
                <Tiles />
                <InfoSection {...homeServices} />
                <InfoQs />
                <Footer />
            </div>
        </>
    )
}