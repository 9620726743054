export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    headline: 'Reshaping the paradigm of the tech industry.',
    description: 'Built by a team of experienced engineers, our apps and services support both industrial and public usage through a perfect balance of practicality and ease of use.',
    buttonLabel: 'LEARN MORE',
    imgStart: false,
    img: require('../images/brain.png').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
    destination: '/about-us'
}

export const homeServices = {
    id: 'services',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headline: 'Expert services by experienced engineers.',
    description: 'We streamline the connection between engineer and customer through web development services and technical consulting designed for maximum efficiency and minimal bureaucracy.',
    buttonLabel: 'LEARN MORE',
    imgStart: true,
    img: require('../images/worker.png').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
    destination: '/services'
}