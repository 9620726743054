import React from 'react';
import './hero.css';
import Video3 from '../../videos/video3.mp4';

export const HeroApps = () => {
    return (
        <div className="hero-container">
            <div className="hero-bg">
                <video className="hero-vid" autoPlay loop muted src={Video3} type='video/mp4' />
            </div>
            <div className="hero-content">
                <h1>APPS</h1>
                <h2>Designed by our engineers for maximum efficency and ease of use.</h2>
            </div>
        </div>
    )
}