import { React } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FaBars } from 'react-icons/fa';
import './Navbar.css';
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks } from './navbarItems';


export const Navbar = ({ toggle }) => {

    return (
        <>
            <Nav>
                <NavbarContainer>
                    <NavLogo to="/">CYBERFRONT</NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars className="icon" />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="/">HOME</NavLinks>
                        </NavItem>
                        <NavItem>
                            <DropdownButton
                                title="APPS"
                                className='nav-links'
                                id="dropdown-btn"
                            >
                                <Dropdown.Item className="dropdown-item" id="dropdown-mw" href="https://www.marketwisdom.net">MARKET WISDOM</Dropdown.Item>
                                <br/>
                                <Dropdown.Item className="dropdown-item" id="dropdown-qs" href="/quantispacer-beta">QuantiSpacer Beta</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item className="dropdown-item" id="dropdown-apps" href="/apps">MORE...</Dropdown.Item>
                            </DropdownButton>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/services">SERVICES</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/about-us">ABOUT US</NavLinks> 
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    );
};
