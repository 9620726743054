import * as api from '../../api/index.js';

export const loadCanvasObjects = () => async (dispatch) => {
    try {
        const { data } = await api.fetchCanvasObjects();

        dispatch({ type: 'FETCH_ALL', payload: data });

    } catch (error) {
        console.log(error.message);
    }
}

export const saveCanvasObjects = (canvasObject) => async (dispatch) => {
    try {
        const { data } = await api.saveCanvasObjects(canvasObject);

        dispatch({ type: 'SAVE', payload: data });
        
    } catch (error) {
        console.log(error);
    }
}

export const deleteCanvasObjects = (id) => async (dispatch) => {
    try {
        await api.deleteCanvasObjects(id);

        dispatch({ type: 'DELETE', payload: id });
        
    } catch (error) {
        console.log(error);
    }
}