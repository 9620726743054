import React from 'react'
import Icon1 from '../../images/mw_logo.png'
import Icon2 from '../../images/qs_logo.png'
import Icon3 from '../../images/op_logo.png'
import { TilesContainer, TilesH1, TilesWrapper, TilesCard, TilesIcon, TilesP } from './tilesdata'

const Tiles = () => {
    return (
        <TilesContainer id="tiles">
            <TilesH1>OUR APPS</TilesH1>
            <TilesWrapper>
                <TilesCard href='https://www.marketwisdom.net' target="_blank">
                    <TilesIcon src={Icon1} />
                    <TilesP>Wise up on the markets and enjoy spam-free job hunting.</TilesP>
                </TilesCard>
                <TilesCard href="/quantispacer-beta" target="_blank">
                    <TilesIcon src={Icon2} />
                    <TilesP>Streamline your construction management all in one tool.</TilesP>
                </TilesCard>
                <TilesCard href="/apps" target="_blank">
                    <TilesIcon src={Icon3} />
                    <TilesP>Unifying the office dynamic. COMING SOON</TilesP>
                </TilesCard>
            </TilesWrapper>
        </TilesContainer>
    )
}

export default Tiles
