import React from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink } from './sidebarItems';

const Sidebar = ( {isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='/' onClick={toggle}>HOME</SidebarLink>
                    <SidebarLink to='/apps' onClick={toggle}>APPS</SidebarLink>
                    <SidebarLink to='/services' onClick={toggle}>SERVICES</SidebarLink>
                    <SidebarLink to='/about-us' onClick={toggle}>ABOUT US</SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
