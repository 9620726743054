import React from 'react'
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink } from './footerdata'
import { FaFacebook, FaYoutube, FaTwitter, FaLinkedin} from 'react-icons/fa';
import '../info/info.css';

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Organisation</FooterLinkTitle>
                            <FooterLink href="/about-us">About Us</FooterLink>
                            <FooterLink href="/apps">Apps</FooterLink>
                            <FooterLink href="/services">Services</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Contact Us</FooterLinkTitle>
                            <FooterLink href='https://www.marketwisdom.net/contact' target="_blank">Contact</FooterLink>
                            <FooterLink href='https://www.marketwisdom.net/contact' target="_blank">Careers</FooterLink>
                            <FooterLink href='https://www.marketwisdom.net/contact' target="_blank">Support</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Resources</FooterLinkTitle>
                            <FooterLink href='https://www.marketwisdom.net' target="_blank">Market Wisdom</FooterLink>
                            <FooterLink href="/">GitHub</FooterLink>
                            <FooterLink href="/">Terms of Service</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Social Media</FooterLinkTitle>
                            <FooterLink href="/">LinkedIn</FooterLink>
                            <FooterLink href='https://twitter.com/MarketWisdom3' target="_blank">Twitter</FooterLink>
                            <FooterLink href="/">YouTube</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/'>
                            CYBERFRONT
                        </SocialLogo>
                        <WebsiteRights>CYBERFRONT 2021</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="/" target="_blank"
                            aria-label="Facebook">
                                <FaFacebook className="icon" />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank"
                            aria-label="YouTube">
                                <FaYoutube className="icon" />
                            </SocialIconLink>
                            <SocialIconLink href='https://twitter.com/MarketWisdom3' target="_blank"
                            aria-label="Twitter">
                                <FaTwitter className="icon" />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank"
                            aria-label="LinkedIn">
                                <FaLinkedin className="icon" />
                            </SocialIconLink>
                        </SocialIcons> 
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer