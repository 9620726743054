import React from 'react'
import './info.css';

export const InfoQs = () => {
    return (
        <>
            <div className = 'qs-container'>
                <div className ='qs-info-wrapper'>
                    <div className = 'services-wrapper'>
                        <h2 className = 'qs-heading'>QuantiSpacer</h2>
                        <p className = 'qs-subtitle'>Beta Version 1.1 Now Available!</p>
                        <a className="qs-btn" href="/quantispacer-beta" target="_blank">TRY THE BETA</a>
                    </div>
                </div>
            </div>
        </>
    );
}