import React from 'react';
import $ from 'jquery';
import { fabric } from 'fabric';
import { Tabs, Tab } from "react-bootstrap";
import 'materialize-css';
import './Area.css';
import './canvasPage.css';
//import grid from "../../images/grid2.jpg";
import grid from "../../images/grid3.png";


export default function QuantiTest() {

  var min = 99;
  var max = 999999;
  var polygonMode = true;
  var pointArray = new Array();
  var lineArray = new Array();
  var areas = new Array();
  var activeLine = "";
  var activeShape = false;
  var lengths = new Array();
  var costs = new Array();
  var startx = new Array();
  var endx = new Array();
  var starty = new Array();
  var endy = new Array();
  var count = new Array();
  var temp = 0;
  var text, canvas, line, isDown, mode;
  var trigger = "1";

  const canvasStyle = {
    border: "1px solid black",
    cursor: "crosshair"
  };

  const rowStyle = {
    height: "100%",
    width: "100%",
  };

  const paddingStyle = {
    padding: "0px"
  };

  $(window).on('load', function(){
      prototypefabric.initCanvas();
      $('#create-polygon').click(function() {
        prototypefabric.polygon.drawPolygon(); 
      });
  });

  var prototypefabric = new function () {
        this.initCanvas = function () {
            canvas = new fabric.Canvas('c', { selection: false });
            fabric.Image.fromURL(grid, function(img) {
              var oImg = img.set({
                width: canvas.getWidth(),
                height: 700
            });
            canvas.setBackgroundImage(oImg);
          });
            canvas.perPixelTargetFind = true;
            canvas.targetFindTolerance = 4;
            fabric.Object.prototype.transparentCorners = false; 
            canvas.setWidth(1055);
            canvas.setHeight(700);
            document.getElementById("costrate").defaultValue = 0;
            $("#select").click(function(){
              mode="select";   
              canvas.selection=true;
            });
            $("#draw").click(function(){
              mode="draw";
            });
            $("#export-button").click(function(e){
              e.stopImmediatePropagation();
              download_table_as_csv('length-table');
            });
            $("#export-area").click(function(e){
              e.stopImmediatePropagation();
              download_table_as_csv('area-table');
            });
            $("#delete-button").click(function(e){
              e.stopImmediatePropagation();
              $("#length-table tr").remove();
            });
            $("#delete-area").click(function(e){
              e.stopImmediatePropagation();
              $("#area-table tr").remove();
            });
            $("#delete-count").click(function(e){
              e.stopImmediatePropagation();
              $("#count-table tr").remove();
            });
            $("#delete").click(function(){
              deleteObjects();
            });
            
            document.getElementById('file').addEventListener("change", function (e) {
              var fileType = e.target.files[0].type;
              var file = e.target.files[0];
              var reader = new FileReader();
              reader.onload = function (f) {
                  var data = f.target.result;
                  if (fileType === 'image/png') { //check if png
                      fabric.Image.fromURL(data, function(img) {
                          canvas.add(img).renderAll();
                          $("#set").click(function(){
                            img.lockUniScaling = true;
                            img.lockMovementX = true;
                            img.lockMovementY = true;
                            img.lockScalingX = true;
                            img.lockScalingY = true;
                            img.selectable = false; 
                            img.evented = false;
                            img.setControlsVisibility({
                              mt: false, 
                              mb: false, 
                              ml: false, 
                              mr: false, 
                              bl: false,
                              br: false, 
                              tl: false, 
                              tr: false,
                              mtr: false
                            });
                          });
                      });
                  } 
                  else {
                      fabric.Image.fromURL(data, function (img) {
                          //var oImg = img.set({left: 0, top: 0, angle: 0,width:100, height:100}).scale(0.9);
                          canvas.add(img).renderAll();
                          canvas.setActiveObject(img);
                          $("#set").click(function(){
                            img.lockUniScaling = true;
                            img.lockMovementX = true;
                            img.lockMovementY = true;
                            img.lockScalingX = true;
                            img.lockScalingY = true;
                            img.selectable = false;
                            img.evented = false;
                            img.setControlsVisibility({
                              mt: false, 
                              mb: false, 
                              ml: false, 
                              mr: false, 
                              bl: false,
                              br: false, 
                              tl: false, 
                              tr: false,
                              mtr: false
                            }); 
                          });
                      });
                  }
              };
              reader.readAsDataURL(file);
            });
            
            canvas.on('mouse:down', function (options) {
              if(mode=="draw"){
                if(document.getElementById('measure-type').value == "area") {
                  if(options.target && options.target.id == pointArray[0].id){
                      prototypefabric.polygon.generatePolygon(pointArray);
                  }
                  if(polygonMode){
                      prototypefabric.polygon.addPoint(options);
                  }
                }
                else if(document.getElementById('measure-type').value == "measure") {
                  if(trigger == "1") {
                    isDown = true;
                    var pointer = canvas.getPointer(options.e);
                    var points = [ pointer.x, pointer.y, pointer.x, pointer.y ];
                    startx[temp] = pointer.x;
                    starty[temp] = pointer.y;
                    line = new fabric.Line(points, {
                      strokeWidth: 5,
                      fill: 'red',
                      stroke: 'red',
                      originX: 'center',
                      originY: 'center',
                      //selectable: false,
                      lockUniScaling: true,
                      lockMovementX: true,
                      lockMovementY: true,
                      lockScalingX: true,
                      lockScalingY: true
                  });
                  canvas.add(line);
                  }
                  else {
                    canvas.forEachObject(function(options){
                      options.setCoords();
                    });
                  } 
                }
                else if(document.getElementById('measure-type').value == "count"){
                  var triangle = new fabric.Triangle({
                    width: 20, 
                    height: 10,
                    angle: 180,
                    left: (options.e.layerX/canvas.getZoom()),
                    top: (options.e.layerY/canvas.getZoom()),
                    stroke: '#FF0000',
                    fill: '#FF0000',
                    originX: 'center',
                    originY: 'center',
                    selectable: false,
                    lockUniScaling: true,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockScalingX: true,
                    lockScalingY: true
                  });    
                  canvas.add(triangle);    
                  canvas.renderAll();       
                }
              }
            });

            canvas.on('mouse:up', function (options) {
              if(mode=="draw"){
                if(document.getElementById('measure-type').value == "measure") {
                  var pointer = canvas.getPointer(options.e);
                  isDown = false;
                  if(trigger == "1") {
                    var result = (Calculate.lineLength(startx[temp], starty[temp], endx[temp], endy[temp])/50).toFixed(2);
                    lengths.push(result);
                    var costRate =  document.getElementById("costrate").value;
                    var cost = (result * costRate);
                    costs.push((cost));
                    $("#length-table").append('<tr><td>' + lengths[lengths.length - 1] + "m" + '</td><td>' + "$" + costRate + '</td><td>' + "$" + costs[costs.length - 1] + '</td></tr>');
                    var total = costs.reduce((a, b) => a + b, 0).toFixed(2);
                    document.getElementById('total-result').innerHTML = total;
                  }
                }
                else if(document.getElementById('measure-type').value == "count"){
                  var objectType = document.getElementById("count-type").value;
                  count.push(objectType);
                  var counter = 0;
                  for(let i=0; i < count.length; i++) {
                    if(count[i] == objectType) {
                      counter = counter + 1;
                    }
                  }
                  $("#count-table").append('<tr><td>' + objectType + '</td><td>' + counter + '</td></tr>');
                }
              }
            });

            canvas.on('mouse:move', function (options) {
              if(mode=="draw"){
                if(document.getElementById('measure-type').value == "area") {
                  if(activeLine && activeLine.class == "line"){
                      var pointer = canvas.getPointer(options.e);
                      activeLine.set({ x2: pointer.x, y2: pointer.y });
      
                      var points = activeShape.get("points");
                      points[pointArray.length] = {
                          x:pointer.x,
                          y:pointer.y
                      }
                      activeShape.set({
                          points: points
                      });
                      canvas.renderAll();
                  }
                  canvas.renderAll();
              }
              else if(document.getElementById('measure-type').value == "measure") {
                canvas.remove(text);
                canvas.renderAll();
                if (!isDown) return;
                var pointer = canvas.getPointer(options.e);
                line.set({ x2: pointer.x, y2: pointer.y });
                endx[temp] = pointer.x;
                endy[temp] = pointer.y;

                if(trigger == "1") {
                  var px = (Calculate.lineLength(startx[temp], starty[temp], endx[temp], endy[temp])/50).toFixed(2);
                  text = new fabric.Text('Length ' + px, { left: endx[temp], top: endy[temp], fontSize: 14.5, fill: 'red' });					
                  canvas.add(text);	
                }
                canvas.renderAll();
              }
            }
          });
        }
    };

    function Area (points) {
        let total = 0;
        for (let i = 0; i < points.length; i++) {
            const addX = points[i].x;
            const addY = points[i === points.length - 1 ? 0 : i + 1].y;
            const subX = points[i === points.length - 1 ? 0 : i + 1].x;
            const subY = points[i].y;
            total += (addX * addY * 0.5) - (subX * subY * 0.5);
        }
        return Math.abs(total);
    }

    var Calculate = {
      lineLength:function(x1, y1, x2, y2){
				return Math.sqrt(Math.pow(x2*1-x1*1, 2)+Math.pow(y2*1-y1*1, 2));
			}
    }

    function deleteObjects(){
      var activeObject = canvas.getActiveObject();
      if (activeObject) {
        canvas.remove(activeObject);
      }
    }

    prototypefabric.polygon = {
        drawPolygon : function() {
            polygonMode = true;
            pointArray = new Array();
            lineArray = new Array();
            activeLine = "";
        },
        addPoint : function(options) {
            var random = Math.floor(Math.random() * (max - min + 1)) + min;
            var id = new Date().getTime() + random;
            var circle = new fabric.Circle({
                radius: 5,
                fill: '#ffffff',
                stroke: '#333333',
                strokeWidth: 0.5,
                left: (options.e.layerX/canvas.getZoom()),
                top: (options.e.layerY/canvas.getZoom()),
                selectable: false,
                hasBorders: false,
                hasControls: false,
                originX:'center',
                originY:'center',
                id:id,
                    objectCaching:false
            });
            if(pointArray.length == 0){
                circle.set({
                    fill:'red'
                })
            }
            var points = [(options.e.layerX/canvas.getZoom()),(options.e.layerY/canvas.getZoom()),(options.e.layerX/canvas.getZoom()),(options.e.layerY/canvas.getZoom())];
            var line = new fabric.Line(points, {
                strokeWidth: 2,
                fill: '#999999',
                stroke: '#999999',
                class:'line',
                originX:'center',
                originY:'center',
                selectable: false,
                hasBorders: false,
                hasControls: false,
                evented: false,
                    objectCaching:false
            });
            if(activeShape){
                var pos = canvas.getPointer(options.e);
                var points = activeShape.get("points");
                points.push({
                    x: pos.x,
                    y: pos.y
                });
                var polygon = new fabric.Polygon(points,{
                    stroke:'#333333',
                    strokeWidth:1,
                    fill: '#cccccc',
                    opacity: 0.3,
                    selectable: false,
                    hasBorders: false,
                    hasControls: false,
                    evented: false,
                    objectCaching:false
                });
                canvas.remove(activeShape);
                canvas.add(polygon);
                activeShape = polygon;
                canvas.renderAll();
            }
            else{
                var polyPoint = [{x:(options.e.layerX/canvas.getZoom()),y:(options.e.layerY/canvas.getZoom())}];
                var polygon = new fabric.Polygon(polyPoint,{
                    stroke:'#333333',
                    strokeWidth:1,
                    fill: '#cccccc',
                    opacity: 0.3,
                    selectable: false,
                    hasBorders: false,
                    hasControls: false,
                    evented: false,
                    objectCaching:false
                });
                activeShape = polygon;
                canvas.add(polygon);
            }
            activeLine = line;

            pointArray.push(circle);
            lineArray.push(line);

            canvas.add(line);
            canvas.add(circle);
            canvas.selection = false;
        },
        generatePolygon : function(pointArray){
            var points = new Array();
            $.each(pointArray,function(index,point){
                points.push({
                    x:point.left,
                    y:point.top
                });
                canvas.remove(point);
            });
            $.each(lineArray,function(index,line){
                canvas.remove(line);
            });
            canvas.remove(activeShape).remove(activeLine);
            var polygon = new fabric.Polygon(points,{
                stroke:'#333333',
                strokeWidth:0.5,
                fill: 'red',
                opacity: 0.45,
                hasBorders: false,
                hasControls: false
            });
            canvas.add(polygon);

            activeLine = null;
            activeShape = null;
            polygonMode = false;
            canvas.selection = true;
            console.log(points);
            $('#calculate-area').click(function() {
                var a = (Area(points)/2410);
                document.getElementById('area-result').innerHTML = a.toFixed(3);
            });
            console.log(areas);
            $('#display-table').click(function() {
                var areas = new Array();
                var a = (Area(points)/2410);
                areas.indexOf(a) === -1 ? areas.push(a) : console.log("This item already exists");
                //areas.push(a);
                for (let i = 0; i <= 0; i++) {
                    $("#area-table").append('<tr><td>' + areas[i] + "m²" + '</td></tr>');
                }
                //this.disabled = true;
            });
          }
    };

    function download_table_as_csv(table_id, separator = ',') {
      // Select rows from table_id
      var rows = document.querySelectorAll('#' + table_id + ' tr');
      // Construct csv
      var csv = [];
      for (var i = 0; i < rows.length; i++) {
          var row = [], cols = rows[i].querySelectorAll('td, th');
          for (var j = 0; j < cols.length; j++) {
              // Clean innertext to remove multiple spaces and jumpline (break csv)
              var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
              data = data.replace(/"/g, '""');
              // Push escaped string
              row.push('"' + data + '"');
          }
          csv.push(row.join(separator));
      }
      var csv_string = csv.join('\n');
      // Download it
      var filename = 'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
      var link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }


  return (
    <>
        <div className="container-fluid" style={rowStyle}>
            <div className="row" style={rowStyle}>
                <div className="col-4">
                    <div className="container-fluid" style={paddingStyle}>
                      <h1>QuantiSpacer</h1>
                      <p>v1.1 beta - Currently in development</p>
                      <div className="main-features">
                        <select name="" id="measure-type">
                            <option value="area">Area</option>
                            <option value="measure">Measure</option>
                            <option value="count">Count</option>
                        </select>
                        <button className="main-btn" id="draw">Draw Mode</button>
                        <button className="main-btn" id="select">Select Mode</button>
                        <button id="delete">Delete Selected</button>
                      </div>
                    </div>
                    <Tabs className="tab-bar" defaultActiveKey="main" id="example">
                    <Tab eventKey="main" title="Import">
                      <div className="home-features">
                        <p>1. Upload your drawing.</p>
                        <input id="file" type="file"></input>
                        <p>2. Resize your drawing to match dimensions. Use the canvas scale as a guide.</p>
                        <p>3. Select ‘Set Drawing Size’ to lock-in your drawing size.</p>
                        <button className="set-btn" id="set">Set Drawing Size</button>
                        <p>You're now ready to begin measuring!</p>
                      </div>
                    </Tab>
                    <Tab eventKey="length" title="Measure">
                      <div className="length-section">
                          <label for="quantity" id="costrate-label">Cost Rate</label>
                            <input type="number" id="costrate" name="quantity" min="0" max="100" placeholder="0"></input>
                            <a id="export-button" href="#">Export Data</a>
                            <button id="delete-button">Clear Table</button>
                            <table id="length-table">
                              <thead>
                                <th>Length</th>
                                <th>Cost Rate</th>
                                <th>Cost</th>
                              </thead>
                            </table>
                            <p id="total-text">Total: $<span id="total-result"></span></p>
                        </div>
                    </Tab>
                    <Tab eventKey="area" title="Area">
                      <div className="area-section">
                          <button id="create-polygon" className="area-btn">New Area</button>
                          <button id="calculate-area" className="calc-btn">Calculate Area</button>
                          <button id="display-table" className="display-btn">Display Table</button>
                          <p id="area-text">Area: <span id="area-result"></span>m²</p>
                          <a id="export-area" href="#">Export Data</a>
                          <button id="delete-area">Clear Table</button>
                          <table id="area-table">
                            <thead>
                              <th>Area</th>
                            </thead>
                          </table>
                      </div>
                    </Tab>
                    <Tab eventKey="count" title="Count">
                      <div className="count-section">
                        <label for="type-input" id="count-label">Object Type</label>
                        <input type="text" id="count-type" name="type-input"></input>
                        <button id="delete-count">Clear Table</button>
                        <table id="count-table">
                            <thead>
                              <th>Object Type</th>
                              <th>Count</th>
                            </thead>
                          </table>
                      </div>
                    </Tab>
                    <Tab eventKey="instructions" title="Instructions">
                      <div className="instructions-page">
                        <div className="disclaimer">
                          <h2>Welcome to the QuantiSpacer beta!</h2>
                          <p>
                            QuantiSpacer is in development and this beta is not representative of the final product. 
                          </p>
                        </div>
                        <div className="instructions">
                          <h3>Length/Cost Calculations:</h3>
                          <p>
                            1.	With ‘Draw Mode’ and ‘Measure’ selected, click and drag over the schematic to measure.
                            <br/>
                            2.	To calculate the cost of measurement, input a cost rate into the ‘Cost Rate’ bar.
                            <br/>
                            3.	For every measurement completed, the length, cost rate and cost will be added to the measurements table.
                          </p>
                          <h3>Area Calculations:</h3>
                          <p>
                            1.	With ‘Draw Mode’ and ‘Area’ selected, click the schematic to add the first point, each additional click will add more points.
                            <br/>
                            2.	Re-select the first point to close your area shape/polygon.
                            <br/>
                            3.	Select ‘Calculate Area’ to calculate the area drawn, the result will be displayed in the Area section.
                            <br/>
                            4.	Select ‘New Area’ to begin drawing a new area to calculate.
                            <br/>
                            5.	Once finished, select ‘Display Area Table’ to display all calculated areas in a table format.
                          </p>
                        </div>
                      </div>
                    </Tab>
                    </Tabs>

              </div>
                <div className="col-4">
                    <div className="section no-pad-bot no-pad-top">
                        <canvas id = "c" style={canvasStyle}></canvas>
                    </div>
                </div>
            </div>
        </div>
    </>
  ) 
}



