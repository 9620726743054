import React from 'react';
import Footer from '../footer/Footer';
import  { HeroServices } from '../hero/hero-services';
import { InfoCivilService, InfoCyberService, InfoMLService, InfoServices, InfoWebService } from '../info/infoservices';
import { Tabs, Tab } from "react-bootstrap";

const Services = () => {
  return (
      <>
          <HeroServices />
          <Tabs className="service-bar" defaultActiveKey="webservice">
            <Tab eventKey="webservice" title="Web Development">
              <InfoWebService />
              <InfoServices />
            </Tab>
            <Tab eventKey="cyberservice" title="Cyber Security">
              <InfoCyberService />
            </Tab>
            <Tab eventKey="machinelearning" title="Machine Learning">
              <InfoMLService />
            </Tab>    
            <Tab eventKey="civilservice" title="Engineering Consulting">
              <InfoCivilService />
            </Tab>    
          </Tabs>       
          <Footer />
      </>
  )
}

export default Services;