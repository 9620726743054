import React from 'react';
import '../App.css';
import Footer from '../footer/Footer';
import { HeroApps } from '../hero/hero-apps';
import { InfoAppsMw, InfoAppsQs, InfoAppsOp } from '../info/infoapps';

const Apps = () => {
  return (
      <div>
          <HeroApps />
          <InfoAppsMw />
          <InfoAppsQs />
          <InfoAppsOp />
          <Footer />
      </div>
  )
}

export default Apps