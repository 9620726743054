import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const Nav = styled.nav`
    background: #000;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    font-size: 1rem;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }

`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 70px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
    
`;

export const NavLogo = styled(Link)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    margin-left: 24px;
    font-family: 'Audiowide';

    :hover {
        color: #fff;
    }

`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 20%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff !important;
        fill: #fff !important;
    }

`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px) {
        display: none;
    }

`;

export const NavItem = styled.li`
    height: 70px;
`;

export const NavLinks = styled(Link)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 19px;
    font-family: 'Exo 2', sans-serif;
    margin-top: 0.5em;

    :hover {
        border-bottom: 3px solid #18CAE6;;
        color: #fff;
    }

`;