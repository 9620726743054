import React, { Fragment, useState, useEffect } from "react";
import { Navbar } from './navbar/Navbar';
import Sidebar from './navbar/sidebar';
import ReactGA from 'react-ga';
import './App.css';
import "react-toastify/dist/ReactToastify.css";
import Home from './pages/Home';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { toast } from "react-toastify";
import Services from './pages/Services';
import Apps from './pages/Apps';
import AboutUs from './pages/About';
import CanvasPage from './canvas/CanvasPage';
import Login from './Login';
import Register from './Register';
import Dev from './Dev';
import QuantiCanvas from './canvas/QuantiCanvas';
import QuantiSpacer from './canvas/Quantispacer';

ReactGA.initialize('UA-207028754-1');
toast.configure();

function App() {

  useEffect(() => {
    ReactGA.pageview('/quantispacer-beta');
  });

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  //const verify_url = "http://localhost:5000/auth/verify";
  const verify_url = process.env.NODE_ENV === 'production' ? "/auth/verify" : "http://localhost:5000/auth/verify";

  const checkAuthenticated = async () => {
    try {
      const res = await fetch(verify_url, {
        method: "POST",
        headers: { jwt_token: localStorage.token }
      });

      const parseRes = await res.json();

      parseRes === true ? setIsAuthenticated(true) : setIsAuthenticated(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    checkAuthenticated();
  }, []);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const setAuth = boolean => {
    setIsAuthenticated(boolean);
  };


  return (
    <Fragment>
      <Router>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/services' component={Services} />
            <Route path='/apps' component={Apps} />
            <Route path='/about-us' component={AboutUs} />
            <Route path='/quantispacer-beta' component={QuantiSpacer} />
            <Route path='/quantispacer-test' component={QuantiCanvas} />
            <Route
              exact
              path="/quantispacer"
              render={props =>
                isAuthenticated ? (
                  <CanvasPage {...props} setAuth={setAuth} />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              exact
              path="/login"
              render={props =>
                !isAuthenticated ? (
                  <Login {...props} setAuth={setAuth} />
                ) : (
                  <Redirect to="/dev" />
                )
              }
            />
            <Route
              exact
              path="/register"
              render={props =>
                !isAuthenticated ? (
                  <Register {...props} setAuth={setAuth} />
                ) : (
                  <Redirect to="/dev" />
                )
              }
            />
            <Route
              exact
              path="/dev"
              render={props =>
                isAuthenticated ? (
                  <Dev {...props} setAuth={setAuth} />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
          </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
