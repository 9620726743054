import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sidebar.css";
import { useDispatch } from 'react-redux';
import { deleteCanvasObjects } from "../../../redux/actions/canvasObjectsActions.js";


function SidebarComp({ canvasObjects }) {

  const dispatch = useDispatch();

  const headers = [
    { label: "Wall", key: "planid" },
    { label: "Cost Rate", key: "costrate" },
    { label: "Length", key: "length" },
    { label: "Cost", key: "cost" }
  ];

  const items = [
    {
      id: 1,
      planId: 1,
      uom: "m",
      name: "Wall",
      costRate: 12.45,
    }
  ];


  
  return (
    <>
      {items.map(item => {
        return (
          <table key={item.uuid} className="table">
            <thead>
              <tr>
                <th>{item.name}</th>
                <th>Cost Rate</th>
                <th>Length</th>
                <th>Cost</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <>
                {canvasObjects
                  .map(sub => {
                    return (
                      <>
                        <tr key={sub.canvasobjects_id}>
                          <td>{sub.planid}</td>
                          <td>{"$ " + sub.costrate + " / " + item.uom}</td>
                          <td>{(sub.length.toFixed(2)) + " " + item.uom}</td>
                          <td>
                            {"$ " + (sub.costrate * ((sub.length)).toFixed(2)).toFixed(2)}
                          </td>
                          <td><button className="delete_btn" onClick={() => dispatch(deleteCanvasObjects(sub.canvasobjects_id))
                            }>X</button></td>
                        </tr>
                      </>
                    );
                  }
                  )
                }
              </>
            </tbody>
          </table>
        );
      })}
      <h2 className ="total">Total: {"$" + canvasObjects.reduce((a, v) => a = a + ((12.45) * ((v.length).toFixed(2))), 0 )}</h2>
      <button className="csv_btn"><CSVLink className="csv_link" data={canvasObjects} headers={headers} filename={"data.csv"}>Export Data</CSVLink></button>
    </>
  );
}


SidebarComp.propTypes = {
  canvasObjects: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired
};



export default SidebarComp;