import styled from 'styled-components';

export const TilesContainer = styled.div`
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(350deg, rgba(6,18,41,1) 45%, rgba(232,239,249,1) 45%);

    @media screen and (max-width: 768px) {
        height: 1100px;
    }

    @media screen and (max-width: 480px) {
        height: 1300px;
    }
`

export const TilesWrapper = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }

`

export const TilesCard = styled.a`
    background: #111;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    text-decoration: none !important;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        box-shadow: 0 6px 20px rgba(23, 138, 247, 0.438);
        -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
        filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
        cursor: pointer;
    }

`

export const TilesIcon = styled.img`
    height: 160px;
    width: 260px;
    margin-bottom: 10px;
`

export const TilesH1 = styled.h1`
    font-size: 65px;
    font-weight: bold;
    color: #111 !important;
    margin-bottom: 64px;
    font-family: 'Play', sans-serif;

    @media screen and (max-width: 768px) {
        font-size: 45px;
    }

    @media screen and (max-width: 480px) {
        font-size: 35px;
    }
`

export const TilesP = styled.p`
    font-size: 18px;
    text-align: center;
    font-family: 'Play', sans-serif;
    color: #fff;
    font-weight: 600;
    margin-top: 0.5em;
    
`
