import React from 'react'
import './info.css';
import { InfoWrapper, InfoRow, Column1, TextWrapper, Heading, WdHeading, Subtitle, BtnWrap, Column2, ImgWrap, Img } from './infopagedata';
import img4 from '../../images/webdev.svg';
import img5 from '../../images/security.svg';
import img6 from '../../images/construction.svg';
import img7 from '../../images/robotics.svg';

export const InfoServices = () => {
    return (
        <>
            <div className = 'webservice-container'>
                <div className ='services-info-wrapper'>
                    <div className = 'services-wrapper'>
                        <h2 className = 'services-heading1'>Why choose CyberFront for web development services?</h2>
                        <p className = 'services-subtitle'>
                            At CyberFront, we focus on providing a highly customisable website that ensures your design vision is represented effectively.
                            <br/>
                            <br/>
                            Whether you are a start-up or a larger corporation, we will work closely with you to ensure the needs of your business are met from both an aesthetic and functional approach.
                            <br/>
                            <br/>
                            Our websites are professionally built and coded cleanly from the ground up using modern design methods and applications.
                            <br/>
                            <br/>
                            Our web development process incorporates the followings procedures:
                            <br/>
                            <br/>
                            <ul>
                                <li>HTML, CSS, JavaScript and React.js for front-end development</li>
                                <li>Python/Django and Node.js for web frameworks and back-end development</li>
                                <li>MySQL or PostgreSQL if databases are required</li>
                                <li>Amazon Web Services (AWS) for infrastructure/server</li>
                                <li>Search Engine Optimisation (SEO) to improve your search engine results</li>
                            </ul>
                            <br/>
                            Contact us to learn more or get a quote to begin your process towards a superior web page.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}


export const InfoWebService = () => {
    return (
        <>
            <div className="wb-container">
                <InfoWrapper>
                    <InfoRow>
                        <Column1>
                            <TextWrapper>
                             <WdHeading>Web Development</WdHeading>
                             <Subtitle>
                                Starting a business and need a website?
                                <br/>
                                <br/>
                                Our web development services will provide you with an affordable website that 
                                represents your business the way you want it.
                                <br/>
                                <br/>
                                With eye-catching designs and easy-to-use interfaces, we maximise presentation while ensuring your
                                products and services are showcased effectively.
                                <br/>
                                <br/>
                                Our websites are professionally built and secured with security certificates and web responsiveness, 
                                allowing your website to be viewed from any device.
                                <br/>
                                <br/>
                                To get started contact us by email or a get quote:
                                <br/>
                                <b>info@cyber-front.net</b>
                             </Subtitle>
                                <BtnWrap>
                                    <a className="app-btn" href='https://www.marketwisdom.net/contact' target="_blank">GET A QUOTE</a>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img4} alt="image" />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </div>
        </>
    );
}


export const InfoCyberService = () => {
    return (
        <>
            <div className="services-container">
                <InfoWrapper>
                    <InfoRow>
                        <Column1>
                            <TextWrapper>
                             <WdHeading>Cyber Security</WdHeading>
                             <Subtitle>
                                In todays age, our security can be exploited through vulnerbilities in our web and mobile applications, personal or business networks, or cloud environments.
                                <br/>
                                <br/>
                                Addressing any vulnerabilities means not only protecting your own data, but also those of your employees and customers.
                                <br/>
                                <br/>
                                We offer a combination of consulting and testing services to analyse the strength of your security systems, and provide solutions for improvement.
                                <br/>
                                <br/>
                                Our consulting services use leading industry standards and practices to help you design your security policies, provide risk assessments, incident management and advice.
                                <br/>
                                <br/>
                                Our security assessments use penetration testing to simulate attacks on your security system. This test aims to detect, correct and prevent any potential weaknesses in your security system.
                                <br/>
                                <br/>
                                To get started contact us by email or a get quote:
                                <br/>
                                <b>info@cyber-front.net</b>
                                
                             </Subtitle>
                                <BtnWrap>
                                    <a className="app-btn" href='https://www.marketwisdom.net/contact' target="_blank"> GET A QUOTE</a>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img5} alt="image" />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </div>
        </>
    );
}

export const InfoCivilService = () => {
    return (
        <>
            <div className="wb-container">
                <InfoWrapper>
                    <InfoRow>
                        <Column1>
                            <TextWrapper>
                             <WdHeading>Engineering Consulting</WdHeading>
                             <Subtitle>
                                CyberFront offers a superior engineering consultation service to developers, investors and  creators both small and large.
                                <br/>
                                <br/>
                                We favour engineering over bureacracy to provide you a more efficient, multi-disciplinary consultation with high quality and cost-effective solutions to your projects.
                                <br/>
                                <br/>
                                Currently our consultation services focus on civil and structural engineering. With expansion to other disciplines in the near future. Some services include:
                                <br/>
                                <br/>
                                <ul>
                                    <li>Structural design and drafting consistent with Australian standards</li>
                                    <li>Steel, concrete and masonry</li>
                                    <li>Buildings and infrastructure (residentual, industrial, roads)</li>
                                    <li>Feasibility and approval management</li>
                                    <li>Construction take-off/cost estimations</li>
                                </ul>
                                To get started contact us by email or a get quote:
                                <br/>
                                <b>info@cyber-front.net</b>
                             </Subtitle>
                                <BtnWrap>
                                    <a className="app-btn" href='https://www.marketwisdom.net/contact' target="_blank">GET A QUOTE</a>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img6} alt="image" />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </div>
        </>
    );
}


export const InfoMLService = () => {
    return (
        <>
            <div className="services-container">
                <InfoWrapper>
                    <InfoRow>
                        <Column1>
                            <TextWrapper>
                             <WdHeading>Machine Learning</WdHeading>
                             <Subtitle>
                                If you're an organisation or an individual that harnesses large amounts of data, our machine learning services can provide you with an easy and efficient way to recieve detailed data science solutions.
                                <br/>
                                <br/>
                                We utilise Python and Tensorflow for supervised and unsupervised model development, deep learning and well-trained neural networks to provide you with a highly detailed analysis on your data.
                                <br/>
                                <br/>
                                Machine learning data services also include:
                                <br/>
                                <ul>
                                    <li>Data analysis</li>
                                    <li>Data visualisation/forecasting</li>
                                    <li>Data mining</li>
                                    <li>Data clustering</li>
                                </ul>
                                We also offer limited chatbot development services using Google's Dialogflow, so you can enhance the user experience of your website or products with a sophisticated bot that can communicate with your users 24/7.
                                <br/>
                                <br/>
                                To get started contact us by email or a get quote:
                                <br/>
                                <b>info@cyber-front.net</b>
                             </Subtitle>
                                <BtnWrap>
                                    <a className="app-btn" href='https://www.marketwisdom.net/contact' target="_blank">GET A QUOTE</a>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img7} alt="image" />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </div>
        </>
    );
}