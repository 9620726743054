import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import './App.css';

const Login = ({ setAuth }) => {
  const [inputs, setInputs] = useState({
    email: "",
    password: ""
  });

  const { email, password } = inputs;
  //const login_url = "http://localhost:5000/auth/login";
  const login_url = process.env.NODE_ENV === 'production' ? "/auth/login" : "http://localhost:5000/auth/login";

  const onChange = e =>
    setInputs({ ...inputs, [e.target.name]: e.target.value });

  const onSubmitForm = async e => {
    e.preventDefault();
    try {
      const body = { email, password };
      const response = await fetch(
        login_url,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json"
          },
          body: JSON.stringify(body)
        }
      );

      const parseRes = await response.json();

      if (parseRes.jwtToken) {
        localStorage.setItem("token", parseRes.jwtToken);
        setAuth(true);
        toast.success("Log in Successful");
      } else {
        setAuth(false);
        toast.error(parseRes);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <Fragment>
      <div className="login">
        <h1 className="login_title">Log In</h1>
        <form className="login_form" onSubmit={onSubmitForm}>
          <p>Email</p>
          <input
            id="loginbox"
            type="text"
            name="email"
            placeholder="email"
            value={email}
            onChange={e => onChange(e)}
            className="form-control my-3"
          />
          <p>Password</p>
          <input
            id="loginbox"
            type="password"
            name="password"
            placeholder="password"
            value={password}
            onChange={e => onChange(e)}
            className="form-control my-3"
          />
          <button className="btn btn-success btn-block" id="login_submit">Submit</button>
        </form>
        <Link className="login_link" to="/register">Register</Link>
      </div>
    </Fragment>
  );
};

export default Login;
