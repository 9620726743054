import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import './App.css';


const Register = ({ setAuth }) => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    name: ""
  });

  const { email, password, name } = inputs;

  //const register_url = "http://localhost:5000/auth/register"
  const register_url = process.env.NODE_ENV === 'production' ? "/auth/register" : "http://localhost:5000/auth/register";

  const onChange = e =>
    setInputs({ ...inputs, [e.target.name]: e.target.value });

  const onSubmitForm = async e => {
    e.preventDefault();
    try {
      const body = { email, password, name };
      const response = await fetch(
        register_url,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json"
          },
          body: JSON.stringify(body)
        }
      );
      const parseRes = await response.json();

      if (parseRes.jwtToken) {
        localStorage.setItem("token", parseRes.jwtToken);
        setAuth(true);
        toast.success("Register Successfull");
      } else {
        setAuth(false);
        toast.error(parseRes);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <Fragment>
      <div className="register">
        <h1 className="login_title">Register</h1>
        <form className="login_form" onSubmit={onSubmitForm}>
          <p>Email</p>
          <input
            id="loginbox"
            type="text"
            name="email"
            value={email}
            placeholder="email"
            onChange={e => onChange(e)}
            className="form-control my-3"
          />
          <p>Password</p>
          <input
            id="loginbox"
            type="password"
            name="password"
            value={password}
            placeholder="password"
            onChange={e => onChange(e)}
            className="form-control my-3"
          />
          <p>Name</p>
          <input
            id="loginbox"
            type="text"
            name="name"
            value={name}
            placeholder="name"
            onChange={e => onChange(e)}
            className="form-control my-3"
          />
          <button className="btn btn-success btn-block" id="login_submit">Submit</button>
        </form>
        <Link className="login_link" to="/login">Login</Link>
      </div>
    </Fragment>
  );
};

export default Register;
