import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';

const Dashboard = ({ setAuth }) => {
  const [name, setName] = useState("");
  //const dashboard_url = "http://localhost:5000/dashboard/";
  const dashboard_url = process.env.NODE_ENV === 'production' ? "/dashboard/" : "http://localhost:5000/dashboard/";

  const getProfile = async () => {
    try {
      const res = await fetch(dashboard_url, {
        method: "POST",
        headers: { jwt_token: localStorage.token }
      });

      const parseData = await res.json();
      setName(parseData.user_name);
    } catch (err) {
      console.error(err.message);
    }
  };

  const logout = async e => {
    e.preventDefault();
    try {
      localStorage.removeItem("token");
      setAuth(false);
      toast.success("Logout successful");
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="dev">
      <h1 className="mt-5">Dev Mode</h1>
      <h2>Welcome!</h2>
      <Link to='/quantispacer'>QuantiSpacer Demo v1.1</Link>
      <br/>
      <br/>
      <button onClick={e => logout(e)} className="btn btn-primary">
        Logout
      </button>
    </div>
  );
};

export default Dashboard;
