import { v4 } from "uuid";

export const TOOL_LINE = "line";

export default context => {
  let line = null;

  const onMouseDown = (x, y) => {
    line = {
      id: v4(),
      tool: TOOL_LINE,
      start: (x, y),
      end: null
    };
    return [line];
  };

  const onMouseMove = (x, y) => {
    if (!line) return;
    drawLine(line, x, y);
  };

  const onMouseUp = (x, y) => {
    if (!line) return;
    onMouseMove(x, y);
    const item = line;
    line = null;
    line.end = { x, y };
    return [item];
  };

  const drawLine = (item, x, y) => {
    context.save();
    context.beginPath();
    context.moveTo(item.start.x, item.start.y);
    context.lineTo(x, y);
    context.closePath();
    context.stroke();
    context.restore();
  };

  const draw = item => drawLine(item, item.end.x, item.end.y);

  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    draw
  };
};
