import React from 'react';
import './hero.css';
import Video4 from '../../videos/video4.mp4';
import { Link } from 'react-router-dom';

export const HeroSection = () => {
    return (
        <div className="hero-container">
            <div className="hero-bg">
                <video className="hero-vid" autoPlay loop muted src={Video4} type='video/mp4' />
            </div>
            <div className="hero-content">
                <h1>A NEW FRONTIER AWAITS...</h1>
                <h2>Bringing applications and services to the cyber world like never before.</h2>
                <div className="hero-btn-wrapper">
                    <Link className="hero-btn" to="/about-us">
                        GET STARTED
                    </Link>
                </div>
            </div>
        </div>
    )
}