import React, { Component } from "react";
import SidebarComp from "./sidebar/SidebarComp";
import { TOOL_LINE } from "./tools/line";

import * as canvasItemActions from "../../redux/actions/canvasObjectsActions";
import "./canvasPage.css";
import def_img from "../../images/fp.png";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//styling
const canvasStyle = {
  border: "1px solid black",
  cursor: "crosshair"
};

const imageStyle = {
  display: "none"
};

const paddingStyle = {
  padding: "0px"
  // border: "1px solid blue"
};

const rowStyle = {
  height: "100%",
  width: "100%"
};


class CanvasPage extends Component {
  // state management
  state = {
    screen: {
      height: window.height,
      width: window.width,
      ratio: window.devicePixelRatio || 1
    },
    canvas: {
      height: null,
      width: null
    },
    context: null,
    snapshot: null,
    imageContext: null,
    mouse: {
      startX: null,
      startY: null,
      prevX: null,
      prevY: null,
      endX: null,
      endY: null,
      draw: false
    },
    tool: "line",
    items: null,
    selectedFile: null,
    costRate: 0
  };

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const c = {
      height: document.getElementById("canvasDiv").clientHeight,
      width: document.getElementById("canvasDiv").clientWidth
    };

    const { canvasObjects, actions } = this.props;

    if (canvasObjects.length === 0) {
      actions.loadCanvasObjects().catch(error => {
        alert("Loading Canvas objects failed" + error);
      });
    }
    this.setState({ canvas: c });
    //console.log(this.props);
    this.initCanvas();
  }

  componentWillReceiveProps() {
    const { actions } = this.props;
    actions.loadCanvasObjects().catch(error => {
      alert("Loading Canvas objects failed" + error);
    });
  }

  initCanvas() {
    //   set up context for canvas
    const imageContext = this.canvasRef.current.getContext("2d");
    const context = this.canvasRef.current.getContext("2d");
    const rect = this.canvasRef.current.getBoundingClientRect();
    // load image
    const img = document.getElementById("image");
    img.onload = () => {
      imageContext.drawImage(img, 0, 0);
    };
    this.setState({
      context: context,
      rect: rect,
      imageContext: imageContext,
      tool: TOOL_LINE
    });
  }

  getCanvasCoordinates(e) {
    const x = e.clientX - this.state.rect.left;
    const y = e.clientY - this.state.rect.top;

    return { x: x, y: y };
  }

  handleDown(e) {
    e.preventDefault();
    this.getSnapshot();
    const position = this.getCanvasCoordinates(e);
    const mouse = {
      startX: position.x,
      startY: position.y,
      prevX: position.x,
      prevY: position.y,
      endX: null,
      endY: null,
      draw: true
    };
    this.setState({ mouse: mouse });
  }

  handleUp(e) {
    e.preventDefault();
    this.restoreSnapshot();
    const mouse = {
      startX: this.state.mouse.startX,
      startY: this.state.mouse.startY,
      endX: e.clientX - this.state.rect.left,
      endY: e.clientY - this.state.rect.top,
      draw: false
    };
    this.drawLine(mouse.endX, mouse.endY);
    this.setState({ mouse: mouse });
    const length = this.getLineLength(mouse);
    this.addCanvasItem(length);
  }

  getLineLength(mouse) {
    const xLength = mouse.endX - mouse.startX;
    const yLength = mouse.endY - mouse.startY;
    let lineLength = Math.sqrt(xLength * xLength + yLength * yLength);
    return lineLength;
  }

  handleMove(e) {
    e.preventDefault();
    if (this.state.mouse.draw) {
      this.restoreSnapshot();
      let x = e.clientX - this.state.rect.left;
      let y = e.clientY - this.state.rect.top;
      this.drawLine(x, y);
      const mouse = {
        startX: this.state.mouse.startX,
        startY: this.state.mouse.startY,
        prevX: x,
        prevY: y,
        draw: true
      };
      this.setState({ mouse: mouse });
    }
  }
 
  handleCostRate = (event) => {
    this.setState({
      costRate: event.target.value
    });
  }

  getSnapshot() {
    const snapshot = this.state.context.getImageData(
      0,
      0,
      this.state.canvas.width,
      this.state.canvas.height
    );
    this.setState({
      snapshot: snapshot
    });
  }

  restoreSnapshot() {
    const context = this.state.context;
    context.putImageData(this.state.snapshot, 0, 0);
  }

  drawLine(x, y) {
    const context = this.state.context;
    // context.clearRect(0, 0, this.state.canvas.width, this.state.canvas.height);
    context.save();
    context.beginPath();
    context.moveTo(this.state.mouse.startX, this.state.mouse.startY);
    context.lineTo(x, y);
    context.strokeStyle = "red";
    context.lineWidth = 9;
    context.lineJoin = context.lineCap = "round";
    context.stroke();
    context.restore();
    this.setState({ context: context });
  }

  addCanvasItem(length) {
    const obj = {
      planId: 1,
      startX: this.state.mouse.startX,
      startY: this.state.mouse.startY,
      endX: this.state.mouse.endX,
      endY: this.state.mouse.endY,
      length: ((length * 2) / 100),
      costRate: this.state.costRate,
      cost: (((length * 2 ) / 100) * (this.state.costRate))

    };
    this.props.actions.saveCanvasObjects(obj);
  }


  render() {
    return (
      <div className="container-fluid" style={rowStyle}>
        <div className="row" style={rowStyle}>
          <div className="col-4">
            <div className="container-fluid" style={paddingStyle}>
              <h1>QuantiSpacer</h1>
              <p>v1.1 demo - Currently in development</p>
            </div>
            <label for="cost-rate" className="label_costRate">Cost Rate ($/m)</label>
            <input name="cost-rate" className="input" value={this.state.costRate} onChange={this.handleCostRate}></input>
            <SidebarComp canvasObjects={this.props.canvasObjects} />
          </div>
          <div id="canvasDiv" className="col-8">
            <canvas
              ref={this.canvasRef}
              width={this.state.canvas.width}
              style={canvasStyle}
              height="700"
              // height={this.state.canvas.height}
              onMouseDown={e => this.handleDown(e)}
              onMouseUp={e => this.handleUp(e)}
              onMouseMove={e => this.handleMove(e)}
            >
              Canvas not supported
            </canvas>
            <img id="image" style={imageStyle} src={def_img} alt="schematic" />
          </div>
        </div>
      </div>
    );
  }
}

CanvasPage.propTypes = {
  canvasObjects: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  //console.log(state);
  return {
    canvasObjects: state.canvasObjects
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadCanvasObjects: bindActionCreators(
        canvasItemActions.loadCanvasObjects,
        dispatch
      ),
      saveCanvasObjects: bindActionCreators(
        canvasItemActions.saveCanvasObjects,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasPage);