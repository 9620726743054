import React from 'react';
import './hero.css';
import Video from '../../videos/video.mp4';

export const HeroAbout = () => {
    return (
        <div className="hero-container">
            <div className="hero-bg">
                <video className="hero-vid" autoPlay loop muted src={Video} type='video/mp4' />
            </div>
            <div className="hero-content">
                <h1>ABOUT US</h1>
            </div>
        </div>
    )
}