import React from 'react'
import './info.css';
import img1 from '../../images/office.svg';
import img2 from '../../images/marketwisdom.png';
import img3 from '../../images/quantispacer_img.png';
import { InfoWrapper, InfoRow, Column1, TextWrapper, Heading, HeadingMw, Subtitle, BtnWrap, Column2, ImgWrap, Img } from './infopagedata';

export const InfoAppsMw = () => {
    return (
        <>
            <div className="mw-container">
                <InfoWrapper>
                    <InfoRow>
                        <Column1>
                            <TextWrapper>
                             <HeadingMw>MARKET WISDOM</HeadingMw>
                             <Subtitle>
                                MARKET WISDOM is a job-seeking platform that puts the users first. We empower our users by providing them with helpful information about the job market, so that they can make the best decisions with the data available to them.
                                <br/>
                                The current job market favours employers and corporations, which often leads to listings of spam posts, low quality jobs, misleading data collection and other predatory behaviour aiming to profit off the vulnerable position of the job seeker.
                                <br/>
                                <br/>
                                Through the use of machine learning, we filter the bottom 20% of job posts by identifying and removing those that display symptoms of spam, privacy-invasion or designed to harvest user information.
                                <br/>
                                We stop them before they reach you.
                                <br/>
                                <br/>
                                MARKET WISDOM is currently a work in progress. We are consistently working to improve our job board, and in the future plan to expand the apps information-providing services to other financial markets.
                             </Subtitle>
                                <BtnWrap>
                                    <a className="app-btn" href='https://www.marketwisdom.net' target="_blank">START NOW</a>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img2} alt="image" />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </div>
        </>
    );
}


export const InfoAppsQs = () => {
    return (
        <>
            <div className="qs-container">
                <InfoWrapper>
                    <InfoRow>
                        <Column1>
                            <TextWrapper>
                             <Heading>QuantiSpacer</Heading>
                             <Subtitle>
                                QuantiSpacer is a construction takeoff and estimation app that aims to be the ultimate tool for streamlining cost estimation and proposals for construction projects.
                                <br/>
                                <br/>
                                Whether you’re a contractor, or working on a personal project, the quantity and dimensions of materials and supplies must be planned and calculated to ensure your project runs efficiently and within budget.
                                <br/>
                                QuantiSpacer will help you achieve this through a focus on accessibility, user-friendliness and focusing on the essentials of your project.
                                <br/>
                                <br/>
                                Simply upload your schematic and make use of our wide range of free features to measure, count objects and calculate costs. Project estimations and proposals have never been so easy and convenient.
                                <br/>
                                <br/>
                                QuantiSpacer is currently under-development and the current version is not representative of the final product. More features will be made available in future updates.
                             </Subtitle>
                                <BtnWrap>
                                    <a className="app-btn" href="/quantispacer-beta" target="_blank">TRY THE BETA</a>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img3} alt="image" />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </div>
        </>
    );
}


export const InfoAppsOp = () => {
    return (
        <>
            <div className="op-container">
                <InfoWrapper>
                    <InfoRow>
                        <Column1>
                            <TextWrapper>
                             <Heading>OfficePlanner</Heading>
                             <Subtitle>
                                OfficePlanner is an exciting new in-development web app that will change the efficiency of office management as we know it.
                                <br/>
                                This app will combine several different innovative features to connect the entire office space within one unified virtual location.
                                <br/>
                                <br/>
                                These features will allow for a more seamless communication between the administration and the office, and also between office branches.
                                Want to know if there is a meeting today? Or which workers are absent? Or perhaps where a worker sits in the office?
                                <br/>
                                OfficePlanner answers all of these questions and more all within one user-friendly system.
                                <br/>
                                Removing the need for all of those pesky FYI emails.
                             </Subtitle>
                                <BtnWrap>
                                    <button className="app-btn" to="/">COMING SOON</button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img1} alt="image" />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </div>
        </>
    );
}