import axios from 'axios';

//const url = 'http://localhost:5000/canvasObjects';
const url = process.env.NODE_ENV === 'production' ? "/canvasObjects" : "http://localhost:5000/canvasObjects";



export const fetchCanvasObjects = () => axios.get(url);
export const saveCanvasObjects = (canvasObject) => axios.post(url, canvasObject);
export const deleteCanvasObjects = (id) => axios.delete(`${url}/${id}`);



